import styles from "./statusButton.module.css";
import React, { useState } from "react";
import {
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import { statusesSelector } from "../../services/selectors/user.selectors";
import {
  getServerStatusText,
  getStatusClass,
} from "../../utils/therapistDocuments";
import { ITreatmentPlan } from "../../types/types";

function StatusButton({
  treatmentPlan,
  document,
  onStatusChange,
  updatable,
}: {
  treatmentPlan?: ITreatmentPlan;
  document?: TTherapistWithUsers | TUsersWithOutTherapist;
  onStatusChange: (newStatusId: number) => void;
  updatable?: boolean;
}) {
  const serverStatuses = useAppSelector(statusesSelector);
  const status =
    serverStatuses.find((s) => s.Id === (document ? document?.Status : treatmentPlan?.appointmentsProgram.statusId)) || undefined;
  const statusId = status?.Id || 0;
  const [toggle, setToggle] = useState({});
  const id = (document ? document.Num.toString() : treatmentPlan?.appointmentsProgram.id.toString()) ?? "0"
  const toggleStatusOptions = (id: string) => {
    setToggle({ [id]: !toggle[id as keyof typeof setToggle] });
  };
  if (!document && !treatmentPlan) return null;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        toggleStatusOptions(id);
      }}
      className={`${styles.statusContainer} ${styles.status} ${status && styles[getStatusClass(statusId)]
        }`}
    >
      <span className={styles.statusPoint} />
      {getServerStatusText(statusId, serverStatuses)}
      {updatable && (
        <ul
          className={`${styles.statusOptionsList} ${toggle[id as keyof typeof setToggle] &&
            styles.statusOptionsList_visible
            }`}
        >
          {serverStatuses.map(
            (s) =>
              s.Id !== 0 && (
                <li
                  key={s.Id}
                  className={`${styles.statusOptionsItem} ${styles[getStatusClass(s.Id)]
                    }`}
                  onClick={() => onStatusChange(s.Id)}
                >
                  {s.Desc}
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
}
export default StatusButton;