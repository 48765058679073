import styles from "./editAvailabilityPopup.module.css";
import { ITimeRange, IAvailability } from "../../../types/types";
import React, { useEffect, useState } from "react";
import {
  decrement,
  hoursRange,
  increment,
  minutesRange,
  newTimeRange,
  weekdays,
} from "../../../utils/constants";
import Modal from "../modal/Modal";
import EditAvailabilityConfirmationPopup from "../editAvailabilityConfirmationPopup/editAvailabilityConfirmationPopup";
import { usePostTherapistAvailabilityMutation } from "../../../services/api/user.api";
import { useAppSelector } from "../../../services/hooks";
import {
  therapistCompanyIdSelector,
  therapistIdSelector,
} from "../../../services/selectors/user.selectors";

interface IEditAvailabilityPopupProps {
  day: IAvailability[];
  dayId: number;
  onClose: () => void;
  specificDate?: Date;
}
function EditAvailabilityPopup({
  day,
  dayId,
  onClose,
  specificDate,
}: IEditAvailabilityPopupProps) {
  const therapistId = useAppSelector(therapistIdSelector);
  const therapistCompanyId = useAppSelector(therapistCompanyIdSelector);
  const [available, setAvailable] = useState<boolean>(day[0]?.isAvailable);
  const [ranges, setRanges] = useState<ITimeRange[]>(
    day.length > 0
      ? day.map((d) => ({
        id: d.id,
        start: d.startTime,
        end: d.endTime,
        statusId: 1,
        specificDate: d.specificDate ? new Date(d.specificDate) : null,
      }))
      : [
        {
          start: `${hoursRange[0]}:${minutesRange[0]}`,
          end: `${hoursRange[hoursRange.length - 1]}:${minutesRange[0]}`,
          statusId: 1,
          specificDate: specificDate ? new Date(specificDate) : null,
        },
      ]
  );
  const [hasChanges, setHasChanges] = useState<number>(0);

  const [toggle, setToggle] = useState({});
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [saveAvailability] = usePostTherapistAvailabilityMutation();
  const toggleTimePicker = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };
  useEffect(() => {
    if (hasChanges < 2) {
      setHasChanges(hasChanges + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ranges]);

  const onSaveButtonClick = () => {
    const availabilityBody: IAvailability[] = ranges.map((r) => ({
      id: r.id ?? 0,
      companyId: therapistCompanyId,
      TherapistsId: Number(therapistId),
      dayId: dayId,
      startTime: r.start,
      endTime: r.end,
      isAvailable: available,
      statusId: r.statusId,
      specificDate: r.specificDate ? r.specificDate.toDateString() : null,
    }));
    saveAvailability(availabilityBody);
  };

  return (
    <article className={styles.section}>
      <h3 className={styles.title}>{weekdays[dayId + 1]?.title}</h3>
      <div className={styles.availability}>
        <input
          type="checkbox"
          name="available"
          id="available"
          defaultChecked={available}
          onChange={() => {
            setAvailable((prev) => !prev);
            setHasChanges((prev) => prev + 2);
          }}
          className={`${styles.toggle} ${available ? styles.toggle_active : styles.toggle_disabled
            }`}
        />
        <label htmlFor="available" className={styles.label}>
          {available ? "זמין" : "לא זמין"}
        </label>
      </div>
      <ul className={styles.timePickersList}>
        {ranges.map(
          (r, index) =>
            r.statusId === 1 &&
            (specificDate ? r.specificDate : !r.specificDate) && (
              <li
                key={index}
                className={`${styles.timePickerLi} ${toggle[index as keyof typeof setToggle] &&
                  styles.timePickerLi_open
                  }`}
                onClick={() => {
                  toggleTimePicker(index);
                  setAvailable(true);
                }}
              >
                <div
                  className={`${styles.timeRange} ${!available && styles.timeRange_disabled
                    }`}
                >
                  <button
                    className={`${styles.btn} ${styles.btn_type_delete} ${!available && styles.btn_type_delete_disabled
                      }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRanges((prev) => [
                        ...prev.slice(0, index),
                        {
                          ...prev[index],
                          statusId: 0,
                        },
                        ...prev.slice(index + 1),
                      ]);
                    }}
                  >
                    &#10006;
                  </button>
                  <div
                    className={`${styles.timePicker} ${!available && styles.timePicker_disabled
                      }`}
                  >
                    <span className={styles.hour}>{r.start}</span>
                  </div>
                  <p className={styles.timePickerText}>עד</p>
                  <div
                    className={`${styles.timePicker} ${!available && styles.timePicker_disabled
                      }`}
                  >
                    <span className={styles.hour}>{r.end}</span>
                  </div>
                </div>
                <div className={styles.timeRange}>
                  <button className={`${styles.btn} ${styles.btn_type_delete}`}>
                    &#10004;
                  </button>
                  <div className={`${styles.select} `}>
                    <button
                      className={`${styles.btn} ${styles.incrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            start: increment(hoursRange, r.start, "h"),
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <button
                      className={`${styles.btn} ${styles.incrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            start: increment(minutesRange, r.start, "m"),
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <span className={styles.selectText}>
                      {r.start.split(":")[0]}
                    </span>
                    <span className={styles.selectText}>
                      {r.start.split(":")[1] ?? "00"}
                    </span>
                    <button
                      className={`${styles.btn} ${styles.decrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            start: decrement(hoursRange, r.start, "h"),
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <button
                      className={`${styles.btn} ${styles.decrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            start: decrement(minutesRange, r.start, "m"), // Update the hours property
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                  </div>
                  <div />
                  <div className={`${styles.select} `}>
                    <button
                      className={`${styles.btn} ${styles.incrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            end: increment(hoursRange, r.end, "h"),
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <button
                      className={`${styles.btn} ${styles.incrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            end: increment(minutesRange, r.end, "m"), // Update the hours property
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <span className={styles.selectText}>
                      {r.end.split(":")[0]}
                    </span>
                    <span className={styles.selectText}>
                      {r.end.split(":")[1] ?? "00"}
                    </span>
                    <button
                      className={`${styles.btn} ${styles.decrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            end: decrement(hoursRange, r.end, "h"),
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                    <button
                      className={`${styles.btn} ${styles.decrementBtn}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRanges((prev) => [
                          ...prev.slice(0, index),
                          {
                            ...prev[index], // Spread the properties of the original object
                            end: decrement(minutesRange, r.end, "m"), // Update the hours property
                          },
                          ...prev.slice(index + 1), // Spread the remaining objects in the array
                        ]);
                      }}
                    />
                  </div>
                </div>
              </li>
            )
        )}
      </ul>
      <button
        className={`${styles.btn} ${styles.btn_type_add} hover`}
        onClick={(e) => {
          e.stopPropagation();
          setAvailable(true);
          setRanges((prev) => [
            ...prev,
            { ...newTimeRange, specificDate: specificDate },
          ]);
        }}
      >
        <span className={styles.plus}>+</span>להוסיף טווח זמן
      </button>
      <button
        type={"button"}
        className={`button ${styles.btn_type_submit} hover`}
        onClick={() => {
          onSaveButtonClick();
          setOpenConfirmationPopup(true);
        }}
        disabled={hasChanges <= 1}
      >
        שמור
      </button>
      {openConfirmationPopup && (
        <Modal
          onClose={() => {
            setOpenConfirmationPopup(false);
            onClose();
          }}
        >
          <EditAvailabilityConfirmationPopup
            onClose={() => {
              setOpenConfirmationPopup(false);
              onClose();
            }}
          />
        </Modal>
      )}
    </article>
  );
}

export default EditAvailabilityPopup;
