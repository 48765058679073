import styles from "./library.module.css";
import React, { ChangeEvent, useState } from "react";
import { libraryThemes } from "../../utils/library";
import { videoExamples } from "../../utils/videoExamples";
import Video from "../../components/video/video";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";

function LibraryPage() {
  const navigate = useNavigate()
  const [active, setActive] = useState<string>(libraryThemes[0]);
  const [value, setValue] = useState<string>("");

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"left"}
      />

      <div className={styles.content}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setValue("");
          }}
        >
          <input
            name={"forumSearch"}
            type={"text"}
            className={styles.input}
            value={value}
            onChange={onChangeValue}
          />
        </form>
        <ul className={styles.themes}>
          {libraryThemes.map((t, index) => (
            <li
              key={index}
              onClick={() => setActive(t)}
              className={`${styles.themesLi} ${t === active && styles.themeLi_active
                }`}
            >
              {t}
            </li>
          ))}
        </ul>
        <label htmlFor={"video"} className={`${styles.btn}`}>
          <span className={styles.plus}>+</span>
          להעלות סרטון חדש
        </label>
        <input
          className={styles.videoInput}
          name="video"
          id="video"
          type="file"
          accept="video/*"
        />
        <ul className={styles.videos}>
          {videoExamples.map((v, index) => (
            <li key={index} className={styles.video}>
              <Video src={v.link} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default LibraryPage;
