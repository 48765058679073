import sidebarStyles from "./sidebar.module.css";
import { Category } from "../../types/types";
import React, { FC } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteCookie } from "../../utils/token";
import { useAppSelector } from "../../services/hooks";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { mobileWidth, testUserEmail } from "../../utils/constants";
import { userSelector } from "../../services/selectors/user.selectors";
import packageJson from "../../../package.json";
import { useActions } from "../../services/hooks/useActions";

interface ISidebar {
  changeCategory: (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    cat: Category
  ) => void;
  activeCategory: Category;
  toggleMenu: () => void;
  openMenu: boolean;
}

const TherapistSidebar: FC<ISidebar> = ({
  changeCategory,
  activeCategory,
  toggleMenu,
  openMenu,
}) => {
  const mobile = useMediaQuery(mobileWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const isChat = location.pathname === "/chat";
  const user = useAppSelector(userSelector);
  const isTestUser = user?.email.toLowerCase() === testUserEmail;
  // const [logoutServer] = authApi.useLogoutMutation();
  const { logout } = useActions();

  return (
    <section
      className={`${sidebarStyles.sidebar} ${isChat && !mobile && sidebarStyles.sidebar_chat
        } ${mobile && openMenu ? sidebarStyles.sidebar_open : ""}`}
      onClick={toggleMenu}
    >
      <div
        className={`${sidebarStyles.sidebar__content_visible} ${openMenu && !mobile
          ? ""
          : mobile
            ? sidebarStyles.sidebar_open
            : sidebarStyles.sidebar__content_small
          }`}
      >
        <button className={sidebarStyles.sidebar__arrow} />
        <div className={sidebarStyles.sidebar__logo} />
        <div className={sidebarStyles.sidebar__welcome}>
          <h2 className={sidebarStyles.sidebar__title}>
            {user.firstName ? user.firstName : "אורח"}
          </h2>
          <img
            onClick={(e) => {
              !mobile && e.stopPropagation();
              navigate("/profile", {
                replace: true,
                state: { pathname: "profile" },
              });

            }}
            className={sidebarStyles.sidebar__avatar}
            src={user.avatar ? user.avatar : avatarPlaceholder}
            alt="תמונת משתמש"
          />
        </div>
        <div className={sidebarStyles.scrollable}>
          <nav className={sidebarStyles.sidebar__pages}>
            <Link
              to={`/${Category.Main}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Main &&
                sidebarStyles.sidebar__page_active
                }`}
              onClick={(e) => {
                changeCategory(e, Category.Main);
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>מסך הבית</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_myDay}`}
              />
            </Link>
            <Link // change all divs to Links when content is ready
              to={`/${Category.Users}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Users &&
                sidebarStyles.sidebar__page_active
                } `}
              onClick={(e) => {
                changeCategory(e, Category.Users);
                navigate(`/${Category.Users}`);
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>משתמשים</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_treatment}`}
              />
            </Link>
            <li
              // to={`/${Category.Calendar}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Calendar &&
                sidebarStyles.sidebar__page_active
                }`}
              onClick={(e) => {
                changeCategory(e, Category.Calendar);
                navigate(`/${Category.Calendar}`);
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>פגישות שלי</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_calendar}`}
              />
            </li>
            <Link
              to={`/${Category.Messages}`}
              className={`${sidebarStyles.sidebar__page} 
                ${sidebarStyles.sidebar__page_messages}
                ${activeCategory === Category.Messages &&
                sidebarStyles.sidebar__page_active
                } `}
              onClick={() => {
                if (isTestUser) {
                  navigate(`/${Category.Messages}`);
                }
              }}
            >
              {user.unreadMessages > 0 && (
                <div className={sidebarStyles.sidebar__messages}>
                  {user.unreadMessages}
                </div>
              )}
              <p className={sidebarStyles.sidebar__pageTitle}>הודעות</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_messages}`}
              />
            </Link>
            <li
              // to={`/${Category.Profile}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Profile &&
                sidebarStyles.sidebar__page_active
                }`}
              onClick={(e) => {
                changeCategory(e, Category.Profile);
                navigate(`/${Category.Profile}`);

              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>הפרופיל שלי</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_profile}`}
              />
            </li>
            <li
              // to={`/${Category.Library}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Library &&
                sidebarStyles.sidebar__page_active
                } ${!isTestUser && sidebarStyles.sidebar__page_disabled}`}
              onClick={(e) => {
                if (isTestUser) {
                  changeCategory(e, Category.Library);
                  navigate(`/${Category.Library}`);
                }
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>
                ספריית פריוונצר
              </p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_prevencher}`}
              />
            </li>

            <li
              // to={`/${Category.Forum}`}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Forum &&
                sidebarStyles.sidebar__page_active
                } ${!isTestUser && sidebarStyles.sidebar__page_disabled}`}
              onClick={(e) => {
                if (isTestUser) {
                  changeCategory(e, Category.Forum);
                  navigate(`/${Category.Forum}`);
                }
              }}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>פורום</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_forum}`}
              />
            </li>
          </nav>
          <ul className={sidebarStyles.sidebar__footer}>
            <li
              // to={`/${Category.Support}`}
              className={`${sidebarStyles.sidebar__footerItem} ${activeCategory === Category.Support &&
                sidebarStyles.sidebar__page_active
                } ${!isTestUser && sidebarStyles.sidebar__page_disabled}`}
              onClick={(e) => {
                if (isTestUser) {
                  changeCategory(e, Category.Support);
                  navigate(`/${Category.Support}`);
                }
              }}
            >
              <p className={sidebarStyles.sidebar__footerTitle}>צור קשר</p>
              <div
                className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_contacts}`}
              />
            </li>
            <li
              onClick={(e) => {
                if (isTestUser) {
                  changeCategory(e, Category.Terms);
                  navigate("/terms", {
                    replace: true,
                  });
                }
              }}
            >
              <div
                className={`${sidebarStyles.sidebar__footerItem} ${activeCategory === Category.Terms &&
                  sidebarStyles.sidebar__page_active
                  } ${!isTestUser && sidebarStyles.sidebar__page_disabled}`}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>
                  מדיניות ופרטיות
                </p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_conditions}`}
                />
              </div>
            </li>
            <li>
              <div
                className={sidebarStyles.sidebar__footerItem}
                onClick={(e) => {
                  e.stopPropagation();
                  // logoutServer(getCookie("accessToken") ?? "");
                  logout();
                  deleteCookie("accessToken");
                  navigate("/");
                }}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>יציאה</p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_logout}`}
                />
              </div>
            </li>
          </ul>
          <p className={sidebarStyles.version}>גרסה: {packageJson.version}</p>
        </div>
      </div>
    </section>
  );
};

export default TherapistSidebar;
