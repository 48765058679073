import { TuserAppointment } from "../services/types/user.types";
import { chatNoteTypes, IOption } from "../utils/constants";

export type TLocationState = {
  pathname: string;
  state: { pathname: string };
};
export interface IDocumentsToFilter {
  status: string;
  urgent: boolean;
  issued: Date;
}
export interface ITherapistDocument {
  id: string;
  userId: string;
  issued: Date;
  urgent: boolean;
  status: string;
  category: string;
}
export interface IPrescription extends ITherapistDocument {
  medicine: string;
  doze: string;
  instructions: string;
  exp: Date;
}
export interface IDatedText {
  id: number;
  text: string;
  date: Date;
}
export interface ITherapist {
  [key: string]: any;
  id: string | number;
  roleId: number;
  firstName: string;
  lastName: string;
  fullName: string;
  account: string;
  avatar: string;
  email: string;
  degree: string;
  phone: string;
  address: string;
  appointments: IAppointment[];
  fields?: string[]; //תחומי טיפול
  password?: string; //remove when server is ready
  unreadMessages: number;
  availability: IAvailability[];
  about?: string;
  videos?: IVideo[];
  mainPageTexts?: IDatedText[];
  companyId: number;
  treatmentPlan: ITreatmentPlan[];
  MigzarId: number;
  GenderId: number;
  SpecializationsId: number;
  vetek: number | null;
  roles: { Id?: number; Name?: string };
}

export interface IUser {
  [key: string]: any;
  id: string;
  guid: string;
  firstName: string;
  lastName: string;
  fullName: string;
  account: string;
  avatar: string;
  email: string;
  phone: string;
  address: string;
  plan: string;
  therapists: ITherapist[];
  appointments: IAppointment[];
  settings: ISettings;
  prescriptions: IPrescription[];
  invoices: IInvoice[];
  videos: IVideo[];
  password: string; //remove when server is ready
  card?: string;
  unreadMessages: number;
  myDayText?: "";
  myTreatmentText?: "";
  passport?: string | null;
  country?: string | null;
  messages?: IMessage[];
}
export enum Category {
  Messages = "messages",
  Main = "main",
  Users = "users",
  Library = "library",
  Support = "support",
  Terms = "terms",
  Profile = "profile",
  Calendar = "calendar",
  Forum = "forum",
}
export interface IAppointment {
  id: number;
  userName: string;
  userId: string;
  date: Date;
}

export interface IMessage {
  id: string;
  date: string;
  message: string;
  unread: boolean;
}
export interface IChatMessage {
  Type: string;
  senderId: number;
  content: string;
  receiverId: number;
  sentDate?: string;
}

export interface IAppointmentDay {
  date: string;
  appointments: any;
}

export interface ISettings {
  sendToEmail: boolean;
  sendAds: boolean;
  sendNotifications: boolean;
}

export interface IInvoice {
  id: string;
  date: Date;
  sum: string;
}

export interface IVideo {
  link: string;
  title: string;
  about: string;
  category?: string;
}

export interface IPopupWithoutProps {
  onClose: () => void;
}

export interface IScannedDoc {
  name: string;
  date: Date;
  status: string;
}

export interface INotification {
  unread: boolean;
  text: string;
  section: string;
}
export interface IForummessage {
  id: string;
  user: {
    userName: string;
    avatar: string;
    tag?: string;
    date: Date;
  };
  message: string;
  replyTo?: string;
}
export interface IForumThread {
  id: string;
  title: string;
  messages: IForummessage[];
}
export interface ITimeRange {
  id?: number;
  start: string;
  end: string;
  statusId: number;
  specificDate?: Date | null;
}

export interface IAvailability {
  id?: number;
  companyId: number;
  TherapistsId: number;
  dayId: number;
  startTime: string;
  endTime: string;
  isAvailable: boolean;
  statusId: number;
  specificDate?: string | null;
}

export interface ITreatmentPlan {
  appointmentsProgram: IAppointmentProgram;
  createUserName: string | null;
  user: TuserAppointment;
}

export interface IAppointmentProgram {
  id: number;
  companyId: number;
  name: string;
  genderId: number;
  migzarId: number;
  vetek: number;
  specializationsId: number;
  usersId: number;
  therapistsId: number;
  roleId: number;
  countOfAppointments: number;
  appointmentsDurationsId: number;
  appointmentsDurationsOpenMinutes: number;
  mediasId: number;
  appointmentsSequenceId: number;
  appointmentsSequenceOpenCountInPeriod: number;
  createUsersId: 1;
  createDate: string;
  updateTherapistsUsersId: number;
  updateTherapistsDate?: string;
  comments?: string;
  statusId: number;
  dateToDelete?: string;
  deleteType?: number;
}

export type TActionReport = {
  duration: IOption | undefined;
  emd: IOption | undefined;
  reason: IOption[] | undefined;
  dsm: IOption[] | undefined;
  medicine: IOption[] | undefined;
  recommendations: IOption[] | undefined;
  rrm: IOption | undefined;
  treatmentWay: IOption | undefined;
};

export type TPrescriptionForm = {
  medicine: string | IOption;
  days: string;
  applicationOptions: string | IOption;
  dosage: string;
  dosageValue: string | IOption;
  numberTimesADay: string;
  instructions: string;
};
export type TLimits = {
  start: number;
  end: number;
};
export type TUserForm = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birth: string;
  genderId: number;
  passport: string;
  [key: string]: any;
};

export type TChatNoteType = (typeof chatNoteTypes)[keyof typeof chatNoteTypes];
