import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import {
  setWsConnection,
  setConnected,
  addMessage,
  setUserTyping,
  endCall,
  setUserConnected,
  setUserDisconnected,
  updateChatData,
} from "../slices/chat.slice";
import { setShowUserJoinedModal } from "../slices/auth.slice";
import { IChatMessage, RootState } from "../types";
import {
  BASE_URL,
  END_CALL_CODE,
  noDataLabel,
  TYPING_CODE,
} from "../../utils/constants";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }), // Base URL is not used for WebSocket
  endpoints: (builder) => ({
    initializeConnection: builder.query<void, string>({
      // @ts-ignore
      async queryFn(userId, { dispatch, getState }) {
        const state = getState() as RootState;
        const isAuth = state.user.isAuth;
        if (!isAuth) return { error: "User is not logged in" };
        const connection = new HubConnectionBuilder()
          .withUrl(`${BASE_URL}chat?uid=${userId}`, {
            withCredentials: false,
          })
          .configureLogging(LogLevel.Information)
          .build();
        connection.onreconnecting((error) => {
          console.warn(
            `Connection lost due to error "${error}". Reconnecting...`
          );
          dispatch(setConnected(false));
        });

        connection.onreconnected((connectionId) => {
          console.log(
            "Connection reestablished with connectionId: ",
            connectionId
          );
          dispatch(setConnected(true));
        });
        connection.on("ReceiveChatData", (data: any) => {
          dispatch(updateChatData(data));
        });
        connection.on(
          "ReceiveChatDataUsers",
          (data: [{ userId: number; online: boolean }]) => {
            dispatch(updateChatData(data));
          }
        );
        connection.on("onMessage", (message: IChatMessage) => {
          if (message.content.includes(TYPING_CODE)) {
            dispatch(setUserTyping(message));
          } else if (message.content.includes(END_CALL_CODE)) {
            const by = message.content.split("by:")[1] || noDataLabel;
            dispatch(endCall(by));
          } else {
            dispatch(addMessage(message));
          }
        });
        connection.on("UserConnected", (data: number) => {
          dispatch(setUserConnected(data));
          data !== +userId && dispatch(setShowUserJoinedModal(data));
        });
        connection.on("UserDisconnected", (disconnectedUserId) => {
          console.log("User disconnected:", disconnectedUserId);
          dispatch(setUserDisconnected(disconnectedUserId));
        });
        connection.on("ReceiveError", (data: any) => {
          console.log(data);
        });
        // connection.on("ReceiveOffer", (offer: string) => {
        //   handleReceiveOffer(offer);
        // });
        // Ensure the connection is in 'Disconnected' state before attempting to start
        if (connection.state === HubConnectionState.Disconnected) {
          try {
            await connection.start();
            dispatch(setWsConnection(connection));
            dispatch(setConnected(true));
            return { data: null };
          } catch (error: any) {
            console.error("Connection error during start:", error);
            // Retry connection if it fails initially
            // await attemptReconnection();
            return { error: error };
          }
        } else {
          console.warn(
            "Connection is already started or not in 'Disconnected' state."
          );
          return { error: "Connection is not in 'Disconnected' state." };
        }
      },
    }),
    sendMessage: builder.mutation<void, IChatMessage>({
      async queryFn(message, { getState }) {
        const state = getState() as RootState;
        const connection = state.chat.connection;

        if (!connection) {
          return { error: "WebSocket connection not established" };
        }

        try {
          await connection.invoke("SendMessage", message);
          return { data: undefined };
        } catch (error: any) {
          console.error("SendMessage error:", error);
          return { error: error }; // Convert error to string or handle appropriately
        }
      },
    }),
  }),
});

export const { useLazyInitializeConnectionQuery } = chatApi;
