import loginStyles from "../../../pages/login/login.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginEmailValidationSchema } from "../../../utils/validation";
import {
  useLoginMutation,
  useLoginWithCodeMutation,
} from "../../../services/api/auth.api";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { useAppSelector } from "../../../services/hooks";
import { useActions } from "../../../services/hooks/useActions";
import { useEffect, useRef } from "react";
import { rolesSelector } from "../../../services/selectors/user.selectors";
import { IRole } from "../../../services/types";
import { getCookie, setCookie } from "../../../utils/token";

interface FormInputRefs {
  email: HTMLInputElement | null;
  password: HTMLInputElement | null;
}

function LoginEmailForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const from: string = location.state?.from || "/main";

  const inputRefs = useRef<FormInputRefs>({
    email: null,
    password: null,
  });

  const [login, { isError, isLoading: isSendingEmailAndPassword }] =
    useLoginMutation();
  const [loginWithCode, { isLoading: isSendingCode }] =
    useLoginWithCodeMutation();

  const loginError = useAppSelector(errorUserSelector);
  const isLoading = isSendingEmailAndPassword || isSendingCode;
  const options = useAppSelector(rolesSelector);
  const { setPhone, setRoleId, setLoginInfo } = useActions();

  const handleLogin = async ({
    email,
    password,
    roleId,
  }: {
    email: string;
    password: string;
    roleId: number | string;
  }) => {
    setLoginInfo({ email, password });
    const response = await login({ email, password, roleId });
    if ("data" in response && response.data.success) {
      const phoneNum = response.data.user.Phone;
      setPhone(phoneNum);
      setRoleId(roleId);
      setCookie("roleId", roleId.toString(), {
        expires: 1000 * 60 * 60 * 24 * 7,
      });
      loginWithCode({ phone: phoneNum, roleId: roleId }).then((response) => {
        if ("data" in response && response.data.success) {
          navigate("enter_code", { state: { from }, replace: true });
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      roleId: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: loginEmailValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleLogin(values);
      setSubmitting(false);
    },
  });

  // Handle saved roleId
  useEffect(() => {
    if (options?.length) {
      const savedRoleId = getCookie("roleId");
      if (
        savedRoleId &&
        options.some((opt: IRole) => opt.id === Number(savedRoleId))
      ) {
        formik.setFieldValue("roleId", savedRoleId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // Handle autofill
  useEffect(() => {
    const syncAutofillValues = () => {
      const { email, password } = inputRefs.current;
      let hasChanges = false;

      if (email?.value && email.value !== formik.values.email) {
        formik.setFieldValue("email", email.value);
        hasChanges = true;
      }

      if (password?.value && password.value !== formik.values.password) {
        formik.setFieldValue("password", password.value);
        hasChanges = true;
      }

      // If values were autofilled, mark fields as touched
      if (hasChanges) {
        const touchedFields: { [key: string]: boolean } = {};
        if (email?.value) touchedFields.email = true;
        if (password?.value) touchedFields.password = true;
        formik.setTouched(touchedFields);
      }
    };

    const timeoutId = setTimeout(syncAutofillValues, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if form is completely filled and valid
  const isFormComplete = Boolean(
    formik.values.email &&
      formik.values.password &&
      formik.values.roleId &&
      !formik.errors.email &&
      !formik.errors.password &&
      !formik.errors.roleId
  );

  return (
    <form onSubmit={formik.handleSubmit} className={loginStyles.login__form}>
      <input
        ref={(el) => (inputRefs.current.email = el)}
        type="email"
        required
        className={`${loginStyles.login__input} ${
          loginStyles.login__input_email
        } 
          ${
            formik.errors.email &&
            formik.touched.email &&
            loginStyles.login__input_invalid
          }`}
        name="email"
        id="email"
        placeholder="דואר אלקטרוני"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        autoComplete="email"
      />
      <input
        ref={(el) => (inputRefs.current.password = el)}
        type="password"
        required
        className={`${loginStyles.login__input} 
          ${
            formik.errors.password &&
            formik.touched.password &&
            loginStyles.login__input_invalid
          }`}
        name="password"
        id="password"
        placeholder="סיסמה"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        autoComplete="current-password"
      />
      <select
        id="roleId"
        name="roleId"
        value={formik.values.roleId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`${loginStyles.login__input} ${loginStyles.login__select} 
          ${
            formik.errors.roleId &&
            formik.touched.roleId &&
            loginStyles.login__input_invalid
          }`}
      >
        <option
          className={loginStyles.login__select__default}
          value=""
          disabled
        >
          תחום התמחות{" "}
        </option>
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>

      {(formik.touched.email || formik.touched.password) &&
        (formik.errors.email || formik.errors.password) && (
          <p className={loginStyles.login__invalid_message}>
            {formik.errors.email ?? formik.errors.password}
          </p>
        )}

      {isError && (
        <p className={loginStyles.login__invalid_message}>{loginError}</p>
      )}

      <Link
        to="/restore_password"
        className={loginStyles.login__restorePasswordLink}
      >
        שכחת סיסמה?
      </Link>

      <button
        type="submit"
        disabled={!isFormComplete || isLoading || formik.isSubmitting}
        className={`button ${loginStyles.login__button} ${
          !isFormComplete || isLoading || formik.isSubmitting
            ? loginStyles.login__button_disabled
            : "hover"
        }`}
      >
        {isLoading ? "שליחה" : "כניסה"}
      </button>
    </form>
  );
}

export default LoginEmailForm;
