import styles from "./prescriptions.module.css";
import { prescriptionsExample } from "../../utils/therapistDocuments";
import { DateObject, Value } from "react-multi-date-picker";
import {
  filterByDate,
  filterDocuments,
  getPrintDate,
  isExpired,
} from "../../utils/utils";
import React, { ChangeEvent, useEffect, useState } from "react";
import { filterObjectsByString } from "../../utils/search";
import { useNavigate, useLocation } from "react-router-dom";
import { users } from "../../utils/user";
import { IPrescription } from "../../types/types";
import FilterButton from "../../components/filterButton/filterButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function PrescriptionsPage() {

  const location = useLocation();
  const id = location.state.UserDetails.UsersId;

  const user = users.find((u) => u.id === id) || users[0];
  const mobile = useMediaQuery(mobileWidth);
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState<string>("");
  const [documentsToShow, setDocumentsToShow] =
    useState<IPrescription[]>(prescriptionsExample);
  const [filter, setFilter] = useState<undefined | string>(undefined);
  const [statusFilter, setStatusFilter] = useState<undefined | string>(
    undefined
  );
  const [dateValue, setDateValue] = useState<Value>(new DateObject());

  useEffect(() => {
    setDocumentsToShow([...prescriptionsExample]);
    if (filter) {
      filterDocuments(
        filter,
        statusFilter,
        prescriptionsExample,
        setDocumentsToShow
      );
    }
  }, [filter, statusFilter]);

  const filterDocumentsByDate = () =>
    setDocumentsToShow(filterByDate(prescriptionsExample, dateValue));

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setDocumentsToShow(
      () =>
        filterObjectsByString(
          event.target.value,
          prescriptionsExample
        ) as IPrescription[]
    );
  };

  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      {mobile && (
        <h3 className={`${styles.title} ${styles.title_mobile} `}>
          מרשמימ של משתמש {user.fullName}
        </h3>
      )}
      <div className={styles.header}>
        <h3 className={styles.title}> מרשמימ של משתמש {user.fullName}</h3>
        <FilterButton
          filter={filter}
          setFilter={setFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          dateValue={dateValue}
          setDateValue={setDateValue}
          filterDocumentsByDate={filterDocumentsByDate}
        />
        <div className={styles.inputContainer}>
          <input
            className={`${styles.input}`}
            type={"text"}
            placeholder={"חיפוש"}
            onChange={onChangeValue}
            value={inputValue}
          />
        </div>
      </div>
      <button
        className={styles.addPrescriptionBtn}
        onClick={() => navigate(`/prescriptions/new`,{
          state: {
            UsersId: id
          }
        })}
      >
        <span className={styles.addPrescriptionBtn__plus}>+</span>
        מרשם חדש
      </button>
      <ul className={styles.tableHeader}>
        <li className={styles.tableHeaderCell}>מס' המרשם</li>
        <li className={`${styles.tableHeaderCell} ${styles.tableCell_hide}`}>
          שם התרופה
        </li>
        <li className={styles.tableHeaderCell}>תאריך פתיחה</li>
        <li className={`${styles.tableHeaderCell} ${styles.tableCell_hide}`}>
          תוקף
        </li>
        <li className={`${styles.tableHeaderCell}`}>לחדש</li>
      </ul>
      {documentsToShow.length > 0 ? (
        <ul className={styles.table}>
          {documentsToShow.map((doc: IPrescription, index) => (
            <li className={styles.tableRow} key={index}>
              <p className={styles.tableCell}>{doc.id}</p>
              <p className={`${styles.tableCell}`}>{doc.medicine}</p>
              <p className={`${styles.tableCell} ${styles.tableCell_hide}`}>
                {doc?.issued && getPrintDate(doc?.issued)}
              </p>
              <div className={`${styles.tableCell} ${styles.tableCell_hide}`}>
                <p
                  className={`${styles.prescription__expiredTag} ${isExpired(doc.exp) &&
                    styles.prescription__expiredTag_expired
                    }`}
                >
                  {isExpired(doc.exp) ? " לא בתוקף" : "בתוקף"}
                </p>
              </div>
              <button
                disabled={!isExpired(doc.exp)}
                className={`${styles.tableCell} ${styles.update} ${!isExpired(doc.exp) && styles.update_disabled
                  }`}
                onClick={() =>
                  navigate(`/prescriptions/update/${doc.medicine}`, {
                    state: {
                      UsersId: id
                    }
                  })
                }
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.noDocuments}>לא נמצאו מרשמים</p>
      )}
    </article>
  );
}

export default PrescriptionsPage;
