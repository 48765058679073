import React, { useEffect, useState } from "react";
import styles from "./filterButton.module.css";
import filterIcon from "../../images/filter_icon.svg";
import {
    filterOptionsTreatmentPlan as options,
} from "../../utils/constants";
import { isToday } from "../../utils/utils";
import { ITreatmentPlan } from "../../types/types";

function FilterButtonTreatmentPlan<
    T extends ITreatmentPlan
>({
    documentsToFilter,
    setDocumentsToFilter,
}: {
    documentsToFilter: T[] | undefined;
    setDocumentsToFilter: (users: ITreatmentPlan[] | undefined) => void;
}) {
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [filterValue, setFilterValue] = useState<undefined | string>(undefined);
    const today = new Date();


    useEffect(() => {
        if (filterValue === "clearFilters") {
            setFilterValue(undefined);
        } else if (filterValue === "today") {
            if (documentsToFilter) {
                setDocumentsToFilter(
                    documentsToFilter.filter((d) => isToday(d.appointmentsProgram.createDate))
                );
            }

        } else if (filterValue === "week") {
            const startOfCurrentWeek = new Date(today);
            startOfCurrentWeek.setDate(today.getDate() - today.getDay());

            // Calculate the start of the last week (Sunday)
            const startOfLastWeek = new Date(startOfCurrentWeek);
            startOfLastWeek.setDate(startOfCurrentWeek.getDate() - 7);
            documentsToFilter &&
                setDocumentsToFilter(
                    documentsToFilter.filter(
                        (d) =>
                            new Date(d.appointmentsProgram.createDate) >= startOfLastWeek &&
                            new Date(d.appointmentsProgram.createDate) <= today
                    )
                );
        } else if (filterValue === "month") {
            const startOfCurrentMonth = new Date(
                today.getFullYear(),
                today.getMonth(),
                1
            );

            setDocumentsToFilter(
                documentsToFilter?.filter(
                    (d) => new Date(d.appointmentsProgram.createDate) >= startOfCurrentMonth
                )
            );
        }

        else {
            setDocumentsToFilter(documentsToFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);


    return (
        <div className={`${styles.container} ${styles.center}`}>
            <button
                className={`${styles.btn} ${styles.btn_type_filter} hover`}
                onClick={(e) => {
                    e.stopPropagation();
                    setFiltersVisible(!filtersVisible);
                }}
            >
                <img className={styles.filterIcon} src={filterIcon} alt="סינון" />
                סינון
            </button>
            {filtersVisible && (
                <ul className={styles.filterOptions}>
                    {options.map((f, index) => (
                        <li
                            key={index}
                            className={`${styles.filterOption}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setFilterValue(f.value);

                            }}
                        >
                            <input
                                type="radio"
                                value={f.value}
                                id={f.value}
                                name="sort"
                                className={styles.radio}
                                checked={filterValue === f.value}
                                onChange={() => { }}
                            />
                            <label htmlFor={f.value} className={styles.label}>
                                {f.label}
                            </label>

                        </li>
                    ))}
                    <li
                        className={styles.filterOption}
                        onClick={() => {
                            setFilterValue("clearFilters");
                            setFiltersVisible(false);
                        }}
                    >
                        <input
                            type="radio"
                            value="clearFilters"
                            id="clearFilters"
                            name="sort"
                            className={styles.radio}
                            checked={filterValue === "clearFilters"}
                            onChange={() => { }}
                        />
                        <label htmlFor="clearFilters" className={styles.label}>
                            ללא מסננים
                        </label>
                    </li>
                </ul>
            )}
            {!!filterValue && <span>!</span>}
        </div>
    );
}

export default FilterButtonTreatmentPlan;
