import styles from "./editDataPage.module.css";
import { userActionsEdit, userActionsEditShort } from "../../utils/user";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth, testUserEmail } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { useEffect, useState } from "react";
import AppointmentButton from "../../components/appointmentButton/AppointmentButton";

function EditDataPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state.UsersId;

  const mobile = useMediaQuery(mobileWidth);
  const therapist = useAppSelector(userSelector);
  const Guid = location.state?.Guid;
  const isTestUser = therapist?.email.toLowerCase() === testUserEmail;
  const [options, setOptions] = useState(userActionsEditShort);

  useEffect(() => {
    setOptions(userActionsEdit);

  }, [isTestUser, setOptions]);

  return (
    <article className={styles.section}>
      {!mobile && (
        <BackArrowButton
          text={"למסך הקודם"}
          onClick={() => navigate(-1)}
          position={"center"}
        />
      )}
      <ul className={styles.editOptions}>
        <AppointmentButton
          userId={userId ?? "-1"}
          media={"1"}
          name={"הזמן צ'אט"}
          propsStyles={styles}
        />

        {options.map((a, index) => (
          <Link
            to={`${(!a.disabled || isTestUser) ? "/users/" + a.path : '#'}`}
            key={index}
            className={`${styles.editOption} ${!(!a.disabled || isTestUser) && styles.optionDisable} hover`}
            state={{
              UserDetails: {
                Guid: Guid,
                UsersId: userId
              }
            }}
          >
            {a.text}
          </Link>
        ))}
        {mobile && (
          <button
            onClick={() => navigate(-1)}
            className={`button ${styles.btn} hover`}
          >
            למסך הקודם
          </button>
        )}
      </ul>
    </article>
  );
}

export default EditDataPage;
