import styles from "./programDatesPopup.module.css";
import { TCalendarAppointment } from "../../../services/types/user.types";
import { useState } from "react";
import CancleAppointmentPopup from "../cancleAppointmentPopup/cancleAppointmentPopup";
import Modal from "../modal/Modal";
import { ITreatmentPlan } from "../../../types/types";
import GeneralSuccessPopup from "../generalSuccessPopup/generalSuccessPopup";
import { isDateAfterToday } from "../../../utils/utils"
import useMediaQuery from "../../../hooks/useMediaQuery";
import { mobileWidth } from "../../../utils/constants";

interface ProgramDatesPopupProps {
  treatmentPlan: ITreatmentPlan
  programDates: TCalendarAppointment[] | null;
  onClose: () => void;
  onFullClose: () => void;
}
function ProgramDatesPopup({ treatmentPlan, programDates: programDatesList, onClose, onFullClose }: ProgramDatesPopupProps) {
  const sortedProgramDatesList = programDatesList ?
    [...programDatesList].sort((a, b) =>
      new Date(a.appointmentDate).getTime() - new Date(b?.appointmentDate).getTime())
    : null
  const [cancle, setCancle] = useState<boolean>(false)
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [currentDate, setCurrentDate] = useState<string>("")
  const [programDates, setProgramDates] = useState<TCalendarAppointment[] | null>(sortedProgramDatesList)

  const mobile = useMediaQuery(mobileWidth);

  const handleProgramDatesChanged = (programDatesChanged: TCalendarAppointment[] | null) => {
    const sortedProgramDates = programDatesChanged ?
      [...programDatesChanged].sort((a, b) =>
        new Date(a.appointmentDate).getTime() - new Date(b?.appointmentDate).getTime())
      : null
    setProgramDates(sortedProgramDates)
  }

  const handleSuccessModal = (change: boolean) => {
    setOpenSuccessModal(change)
  }

  return (
    <div className={styles.popup}>
      <article className={styles.popupArticle}>
        <h2 className={styles.popup__title}>
          מועדי התוכנית      </h2>
        {programDates && programDates.length > 0 && (
          <ul className={styles.inquiries}>
            <li className={`${styles.inquiry} ${styles.inquiryHeader}`}>
              <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                {mobile ? "מספר" : "מספר מפגש"}            </p>
              <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                תאריך                        </p>
              <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                {mobile ? "התחלה" : "שעת התחלה"}           </p>
              <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                {mobile ? "סיום" : "שעת סיום"}            </p>

              <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                סטטוס            </p>
            </li>
          </ul>
        )}


        {programDates && programDates.length > 0 ?
          (
            programDates
              .map(
                (date, index) =>
                (
                  <li
                    key={index}
                    className={`${styles.inquiry} ${index % 2 === 0 && styles.inquiry_colored
                      }`}

                  >
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {index + 1}                </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {new Date(date.appointmentDate).toLocaleDateString('en-GB')}              </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {date.startTime?.split(':').slice(0, 2).join(':')}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {date.endTime?.split(':').slice(0, 2).join(':')}
                    </p>

                    {isDateAfterToday(date.appointmentDate) ? (<button
                      className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                      onClick={() => {
                        setCurrentDate(new Date(date.appointmentDate).toLocaleDateString('en-GB'));
                        setCancle(true)
                      }}
                    >
                      בטל
                    </button>) : (<button
                      className={`${styles.inquiryCell} button button_white ${styles.btn_take} ${styles.btn_full}`}
                      disabled={true}
                    >
                      נעול
                    </button>)}
                    <span
                      className={styles.inquiryRRM}
                    />
                  </li>
                )
              ))
          // : isLoading ? (
          //   <div className={styles.text}>
          //     <Loader />
          //   </div>
          // )
          :
          (
            <p className={styles.text}>
              לא נמצאו מועדים
            </p>
          )
        }


        {cancle && (
          <Modal
            onClose={() => {
              setCancle(false);
            }}
          >
            <CancleAppointmentPopup
              treatmentPlan={treatmentPlan}
              date={currentDate}
              setProgramDates={handleProgramDatesChanged}
              onSuccess={handleSuccessModal}
              onClose={() => {
                setCancle(false);
              }}
              onFullClose={() => {
                setCancle(false);
                onFullClose();
              }}
            />
          </Modal>
        )}
        {openSuccessModal && (
          <Modal
            onClose={() => {
              setOpenSuccessModal(false);
            }}
          >
            <GeneralSuccessPopup
              onClose={() => {
                setOpenSuccessModal(false);
              }}
            />
          </Modal>
        )}
      </article>
    </div>
  );
}

export default ProgramDatesPopup;
