import styles from "./mainPage.module.css";
import reportsIcon from "../../images/reports_icon.svg"
import calendar from "../../images/calendar.png"
import React, { useEffect, useRef, useState } from "react";
import {
  RRMListDefault,
  testUserEmail,
  therapistLinks,
  mainPageTexts,
  mobileWidth,
} from "../../utils/constants";
import venchy from "../../images/questionnaire_doctor.png";
import { getPrintDate, replaceVariables } from "../../utils/utils";
import Slider from "../../components/slider/slider";
import Pagination from "../../components/pagination/pagination";
import useMediaQuery from "../../hooks/useMediaQuery";
import Document from "../../components/document/document";
import {
  useGetMediasQuery,
  useGetStatusesQuery,
  useGetAllOpenTreatmentPlansMutation,
  useLazyGetMainPageInfoQuery,
} from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import { useActions } from "../../services/hooks/useActions";
import {
  mainPageUpdatesSelector,
  mediasSelector,
  statusesSelector,
  therapistWithUsersSelector,
  allTreatmentPlansSelector,
  userSelector,
  usersWithOutTherapistSelector,
  groupedUsersByFakeCompanyIdSelector,
  currentInquirySelector,
  currentTreatmentPlanSelector
} from "../../services/selectors/user.selectors";
import { IDatedText, ITreatmentPlan, TLimits } from "../../types/types";
import {
  TGroupedUsersWithoutTherapistByFakeCompanyId,
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { getServerStatusText } from "../../utils/therapistDocuments";
import { useNavigate } from "react-router-dom";
import ListPagination from "../../components/listPagination/listPagination";
import FilterButtonMainPage from "../../components/filterButton/filterButtonMainPage";
import Loader from "../../components/loader/loader";
import TreatmentDocument from "../../components/document/treatmentDocument";
import FilterButtonTreatmentPlan from "../../components/filterButton/filterButtonTreatmentPlan";

function MainPage() {
  const navigate = useNavigate();

  const { setCurrentInquiry, setCurrentTreatmentPlan } = useActions();
  const inquiriesRefs = useRef<Map<number, HTMLLIElement | null>>(new Map());
  const treatmentPlansRefs = useRef<Map<number, HTMLLIElement | null>>(new Map());


  const therapist = useAppSelector(userSelector);
  const serverStatuses = useAppSelector(statusesSelector);
  const treatmentPlans = useAppSelector(allTreatmentPlansSelector)
  const medias = useAppSelector(mediasSelector)
  const updates = useAppSelector(mainPageUpdatesSelector) as IDatedText[];
  const therapistWithUsers = useAppSelector(therapistWithUsersSelector);
  const currentInquiry = useAppSelector(currentInquirySelector)
  const currentTreatmentPlan = useAppSelector(currentTreatmentPlanSelector)

  const usersWithOutTherapist = useAppSelector(usersWithOutTherapistSelector);
  const groupedUsersByFakeCompanyId = useAppSelector(groupedUsersByFakeCompanyIdSelector);

  const isTestUser = therapist?.email.toLowerCase() === testUserEmail;

  useGetStatusesQuery();
  useGetMediasQuery();
  const [getTreatmentPlan, { isError: isTreatmentPlansError }] = useGetAllOpenTreatmentPlansMutation();
  const [getMainPageInfo, { isLoading: isMainPageInfoLoading, isError }] =
    useLazyGetMainPageInfoQuery();
  const mobile = useMediaQuery(mobileWidth);


  const [activeIndex, setActiveIndex] = useState(0);
  const [treatmentDocumentToShow, setTreatmentDocumentToShow] = useState<ITreatmentPlan | null>(null);
  const [openDocument, setOpenDocument] = useState(false);
  const [openTreatmentDocument, setOpenTreatmentDocument] = useState(false);
  const [documentToShow, setDocumentToShow] = useState<
    TTherapistWithUsers | TUsersWithOutTherapist | null
  >(null);

  const [treatmentPlansToshow, setTreatmentPlansToshow] = useState<ITreatmentPlan[] | null | undefined>(treatmentPlans);

  const [therapistWithUsersToShow, setTherapistWithUsersToShow] = useState<
    TTherapistWithUsers[] | undefined
  >(undefined);
  const [groupedUsersByFakeCompanyIdToShow, setGroupedUsersByFakeCompanyIdToShow] = useState<
    TGroupedUsersWithoutTherapistByFakeCompanyId | undefined
  >(undefined);
  const [therapistWithUsersLimits, setTherapistWithUsersLimits] =
    useState<TLimits>({ start: 1, end: 1 });
  const [groupedUsersByFakeCompanyIdLimit, setGroupedUsersByFakeCompanyIdLimit] = useState<
    { FakeComapnyId: number | string, limit: TLimits }[] | undefined
  >(
    undefined);
  const [treatmentPlanLimits, setTreatmentPlanLimits] = useState<TLimits>(
    { start: 1, end: 1 }
  );

  //limit inquiries to 7
  const limitNumber = 7;
  const isLoading = isMainPageInfoLoading || !therapist.id;

  useEffect(() => {
    if (therapist.id) {
      getMainPageInfo(therapist.id);
      getTreatmentPlan({ companyId: therapist.companyId, therapistName: therapist.fullName, therapistId: therapist.id })
  
    }
  }, [therapist.id, therapist.companyId, therapist.fullName, getMainPageInfo, getTreatmentPlan]);

  useEffect(() => {
    if (therapistWithUsersToShow && therapistWithUsersToShow.length > 0) {
      setTherapistWithUsersLimits((prev) => ({
        ...prev,
        end:
          limitNumber < therapistWithUsersToShow.length
            ? limitNumber
            : therapistWithUsersToShow.length,
      }));
    }

    if (groupedUsersByFakeCompanyIdToShow && Object.keys(groupedUsersByFakeCompanyIdToShow).length > 0) {
      const transformedGroups = Object.entries(groupedUsersByFakeCompanyId).map(([key, items]) => {
        return {
          FakeComapnyId: key,
          limit: {
            start: groupedUsersByFakeCompanyIdLimit?.find(li => li.FakeComapnyId.toString() === key)?.limit.start ?? 1,
            end: limitNumber < (items?.length ?? 0)
              ? limitNumber
              : items?.length ?? 0
          },
        };
      });
      setGroupedUsersByFakeCompanyIdLimit(transformedGroups);
    }

    if (treatmentPlansToshow && treatmentPlansToshow.length > 0) {
      setTreatmentPlanLimits((prev) => ({
        ...prev,
        end:
          limitNumber < treatmentPlans.length
            ? limitNumber
            : treatmentPlans.length,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupedUsersByFakeCompanyIdToShow,
    therapistWithUsersToShow,
    treatmentPlansToshow
  ]);

  useEffect(() => {
    if (therapistWithUsers) setTherapistWithUsersToShow(therapistWithUsers);
    if (groupedUsersByFakeCompanyId) setGroupedUsersByFakeCompanyIdToShow(groupedUsersByFakeCompanyId)
  }, [therapistWithUsers, groupedUsersByFakeCompanyId]);

  //update document to show possible changes

  useEffect(() => {
    const findDocument = (num: string | number) =>
      therapistWithUsers?.find((item) => item.Num === num) ||
      usersWithOutTherapist?.find((item) => item.Num === num);
    if (documentToShow) {
      const updated = findDocument(documentToShow.Num);
      setDocumentToShow(updated || null);
    }
  }, [
    therapistWithUsers,
    documentToShow,
    usersWithOutTherapist,
    groupedUsersByFakeCompanyId
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let targetRef: HTMLLIElement | null | undefined;
      if (currentInquiry) {
        targetRef = inquiriesRefs.current.get(currentInquiry);
      } else if (currentTreatmentPlan) {
        targetRef = treatmentPlansRefs.current.get(currentTreatmentPlan);
      }
      if (targetRef) {
        targetRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

    }, 100);
    return () => clearTimeout(timeout); // Cleanup timeout if the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const showDocument = (doc: TTherapistWithUsers | TUsersWithOutTherapist) => {
    setDocumentToShow(doc);
    setOpenDocument(true);
    setCurrentInquiry(doc.Num);
    setOpenTreatmentDocument(false);
  };

  const showTreatmentDocument = (plan: ITreatmentPlan) => {
    setCurrentTreatmentPlan(plan.appointmentsProgram.id)
    setTreatmentDocumentToShow(plan)
    setOpenTreatmentDocument(true);
  }

  const setFilterToShow = (users: TTherapistWithUsers[] | TUsersWithOutTherapist[] | undefined, fakeCompanyId?: string) => {
    if (fakeCompanyId) {
      setGroupedUsersByFakeCompanyIdToShow((prev) => {
        const updatedState = {
          ...(prev || {}),
          [fakeCompanyId]: users ? [...users] : undefined,
        };

        return updatedState as TGroupedUsersWithoutTherapistByFakeCompanyId;
      });
    }
    else {
      setTherapistWithUsersToShow(users as TTherapistWithUsers[])
    }
  }

  const setFilterTreatmentToShow = (treatments: ITreatmentPlan[] | undefined) => {
    setTreatmentPlansToshow(treatments)
  }

  return (
    <section className={styles.section}>
      <ul className={styles.tabs}>
        {therapistLinks.map((tab) => (
          <li //change to Link when needed
            key={tab.title}
            // to={`/${tab.value}`}
            className={`${styles.tab} ${(tab.value !== "messages" && tab.value !== "calendar") && styles.tab_disabled
              } ${isTestUser && "hover"}`}
            onClick={() => {
              if (tab.value === "messages" || tab.value === "calendar") {
                navigate(`/${tab.value}`);
              }
            }}
          >
            <img src={tab.img} alt={tab.title} className={styles.tabsImg} />
            <p className={styles.tabText}>{tab.title}</p>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        {documentToShow && (
          <Document
            document={documentToShow}
            openDocument={openDocument}
            onClose={() => { setOpenDocument(false); setCurrentInquiry(null) }}
          />
        )}
        {treatmentDocumentToShow && (
          <TreatmentDocument
            treatmentPlan={treatmentDocumentToShow}
            openDocument={openTreatmentDocument}
            onClose={() => { setOpenTreatmentDocument(false); setCurrentTreatmentPlan(null) }}
          />
        )}
        <article className={styles.applications}>
          <div className={styles.home__section}>
            <h4 className={styles.home__sectionTitle}>עדכונים</h4>
            <div className={styles.notifications}>
              <div className={styles.notifications__venchy}>
                <img
                  src={venchy}
                  className={styles.notifications__img}
                  alt={"תמונה של וונצ’י"}
                />
                <div>
                  <p className={styles.notifications__name}>וונצ’י</p>
                  <p className={styles.notifications__date}>
                    {getPrintDate(updates[activeIndex].date)}
                  </p>
                </div>
              </div>
              <Slider
                items={updates.map((u) => replaceVariables(u.text, therapist))}
                className={styles.notifications__text}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
              <button
                className={styles.nextBtn}
                onClick={() =>
                  setActiveIndex((prevIndex) =>
                    prevIndex === updates.length - 1 ? 0 : prevIndex + 1
                  )
                }
              />
            </div>
          </div>
          <Pagination
            items={mainPageTexts.map((t) => t.text)}
            onClick={(index: number) => setActiveIndex(index)}
            activeIndex={activeIndex}
          />
        </article>

        <>
          {groupedUsersByFakeCompanyIdToShow &&
            Object.keys(groupedUsersByFakeCompanyIdToShow).length > 0 &&
            Object.entries(groupedUsersByFakeCompanyIdToShow).map(
              ([key, items]) => {
                const limit = groupedUsersByFakeCompanyIdLimit?.find(li => (li.FakeComapnyId === 'null' &&
                  !groupedUsersByFakeCompanyId?.[key as keyof typeof groupedUsersByFakeCompanyId]?.[0]?.FakeCompanyId) ||
                  (Number(li.FakeComapnyId) === groupedUsersByFakeCompanyId?.[key as keyof typeof groupedUsersByFakeCompanyId]?.[0]?.FakeCompanyId))

                return (
                  <div key={key}>
                    <article className={styles.home__section}>
                      <h4 className={styles.home__sectionTitle}>
                        {key === 'null'
                          ? "פניות חדשות"
                          : `פניות חדשות של מרכז ${groupedUsersByFakeCompanyId?.[key as keyof typeof groupedUsersByFakeCompanyId]?.[0]?.FakeCompanyName ?? ""}`}                      </h4>
                      {(groupedUsersByFakeCompanyId &&
                        Object.entries(groupedUsersByFakeCompanyId).length > 1 &&
                        groupedUsersByFakeCompanyId['null']?.length !== 0) ? (
                        <ul className={styles.inquiries}>
                          <li className={`${styles.inquiry} ${styles.inquiry_contact} ${styles.inquiryHeader}`}>
                            <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                              מס' פניה
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                              תאריך
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                              שם מש'
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                              מחזור
                            </p>

                            <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                              {mobile ? "נושא" : "נושא פניה"}
                            </p>
                            <div
                              className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                            >
                              {items && (
                                <FilterButtonMainPage
                                  documentsToFilter={groupedUsersByFakeCompanyId[key as keyof typeof groupedUsersByFakeCompanyId]}
                                  setDocumentsToFilter={setFilterToShow}
                                  fakeCompanyId={key}
                                />
                              )}
                            </div>
                          </li>
                        </ul>
                      ) : isLoading ? (
                        <div className={styles.text}>
                          <Loader />
                        </div>
                      ) : (
                        <p className={styles.text}>
                          {isError
                            ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                            : "אין פניות חדשות"}
                        </p>
                      )}
                      {items && items.length > 0 &&
                        items.map(
                          (i, index) => {
                            return limit &&
                              index >= limit.limit.start - 1 &&
                              index < limit?.limit.end && (
                                <li
                                  key={i.Num}
                                  className={`${styles.inquiry} ${styles.inquiry_contact} 
                                  ${index % 2 === 0 && styles.inquiry_colored}
                                   ${i.Num === currentInquiry ? styles.inquiry_selected : ""}`}
                                  onClick={() => showDocument(i)}
                                  ref={el => inquiriesRefs.current.set(i.Num, el)}
                                >
                                  {i.AppointmentsId &&
                                    i.AppointmentsTypeId === 1 ?
                                    <img
                                      src={calendar}
                                      alt="isOnline"
                                      className={`${styles.icon} ${styles.online_button}  ${styles.icon_animation}`}
                                    />

                                    : (i.AppointmentsTypeId === 2) &&
                                    <img
                                      src={calendar}
                                      alt="isOnline"
                                      className={`${styles.icon} ${styles.online_button}  ${styles.icon_blue}`}
                                    />
                                  }
                                  <p
                                    className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                                  >
                                    {i.Num}
                                  </p>
                                  <p
                                    className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                                  >
                                    {`${getPrintDate(new Date(i.Timestamp))}`}
                                  </p>
                                  <p
                                    className={`${styles.inquiryCell} ${styles.inquiryName}`}
                                  >
                                    {i.FirstName || ""} {i.LastName || ""}
                                  </p>
                                  <p
                                    className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                                  >
                                    {i.Cycle}
                                  </p>
                                  <p
                                    className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                                  >
                                    {i.QuestionnaireCompaniesType}
                                  </p>
                                  <button
                                    className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                                  >
                                    לקחת
                                  </button>
                                  <span
                                    className={styles.inquiryRRM}
                                    style={{
                                      backgroundColor:
                                        RRMListDefault.find((item) => item.Id === i.GeneralRRMSEQ)
                                          ?.Color || "",
                                    }}
                                  />
                                </li>
                              )
                          }
                        )}
                    </article>
                    {items && items.length > 0 && limit &&
                      (
                        <ListPagination
                          limits={limit.limit}
                          total={items.length || 0}
                          setLimits={setGroupedUsersByFakeCompanyIdLimit}
                          step={limitNumber}
                          groupKey={limit.FakeComapnyId}
                        />
                      )}
                  </div>)
              })}
        </>
        <article className={styles.home__section}>
          <h4 className={styles.home__sectionTitle}>פניות בטיפול שלי</h4>
          {therapistWithUsers && therapistWithUsers.length > 0 ? (
            <ul className={styles.inquiries}>
              <li className={`${styles.inquiry} ${styles.inquiry_my_contact} ${styles.inquiryHeader}`}>
                <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                  מס' פניה
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                  תאריך
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                  שם מש'
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                  מחזור
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                  {mobile ? "נושא" : "נושא פניה"}
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryAction}`}>
                  דו"ח
                </p>
                <div
                  className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                >
                  {therapistWithUsers && (
                    <FilterButtonMainPage
                      documentsToFilter={therapistWithUsers}
                      setDocumentsToFilter={setFilterToShow}
                    />
                  )}
                </div>
              </li>
            </ul>
          ) : isLoading ? (
            <div className={styles.text}>
              <Loader />
            </div>
          ) : (
            <p className={styles.text}>
              {isError
                ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                : "אין פניות חדשות"}
            </p>
          )}
          {therapistWithUsersToShow &&
            therapistWithUsersToShow.map(
              (i, index) =>
                index >= therapistWithUsersLimits.start - 1 &&
                index < therapistWithUsersLimits.end && (
                  <li
                    key={i.Num}
                    className={`${styles.inquiry} ${styles.inquiry_my_contact}
                     ${i.Num === currentInquiry ? styles.inquiry_selected : ""}
                     ${index % 2 === 0 && styles.inquiry_colored}`}
                    onClick={() => showDocument(i)}
                    ref={el => inquiriesRefs.current.set(i.Num, el)}
                  >
                    {i.AppointmentsId ?
                      (!i.AppointmentsActive ?
                        (<img
                          src={calendar}
                          alt="isOnline"
                          className={`${styles.icon} ${styles.online_button} ${styles.icon_disabled}`}
                        />)
                        :
                        i.AppointmentsTypeId === 1 ?
                          (<img
                            src={calendar}
                            alt="isOnline"
                            className={`${styles.icon} ${styles.online_button} ${styles.icon_animation}`}
                          />)
                          : (i.AppointmentsTypeId === 2 || !i.AppointmentsTypeId) &&
                          (<img
                            src={calendar}
                            alt="isOnline"
                            className={`${styles.icon} ${styles.online_button} ${styles.icon_blue}`}
                          />)
                      ) : <></>}
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {i.Num}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {`${getPrintDate(new Date(i.Timestamp))}`}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {i.FirstName || ""} {i.LastName || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {i.Cycle}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                    >
                      {i.QuestionnaireCompaniesType}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryAction}`}
                    >
                      {i.TherapistActionId ?
                        <img
                          src={reportsIcon}
                          alt='דו"ח פעולה'
                          className={`${styles.icon}`}
                          title='דו"ח פעולה'
                        />
                        :
                        '--'}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                    >
                      {getServerStatusText(i.Status, serverStatuses)}
                    </p>
                    <span
                      className={styles.inquiryRRM}
                      style={{
                        backgroundColor:
                          RRMListDefault.find((item) => item.Id === i.GeneralRRMSEQ)
                            ?.Color || "",
                      }}
                    />
                  </li>
                )
            )}
        </article>
        {therapistWithUsersToShow && therapistWithUsersToShow.length > 0 && (
          <ListPagination
            limits={therapistWithUsersLimits}
            total={therapistWithUsersToShow?.length || 0}
            setLimits={setTherapistWithUsersLimits}
            step={limitNumber}
          />
        )}
        <article className={styles.home__section}>
          <h4 className={styles.home__sectionTitle}>
            תכניות טיפול חדשות          </h4>
          {treatmentPlans && treatmentPlans.length > 0 ? (
            <ul className={styles.inquiries}>
              <li className={`${styles.inquiry} ${styles.inquiry_contact} ${styles.inquiryHeader}`}>
                <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                  מס' תכנית טיפול
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                  תאריך פתיחה                        </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                  שם מש'
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                  שם תוכנית
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                  {mobile ? "אופן" : "אופן הטיפול"}
                </p>
                <div
                  className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                >
                  <FilterButtonTreatmentPlan
                    documentsToFilter={treatmentPlans}
                    setDocumentsToFilter={setFilterTreatmentToShow}
                  />
                </div>
              </li>
            </ul>
          ) : isLoading ? (
            <div className={styles.text}>
              <Loader />
            </div>
          ) : (
            <p className={styles.text}>
              {isTreatmentPlansError
                ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                : "אין תוכניות טיפול חדשות"}
            </p>
          )}
          {treatmentPlansToshow &&
            treatmentPlansToshow.map(
              (i, index) =>
                index >= treatmentPlanLimits.start - 1 &&
                index < treatmentPlanLimits.end &&
                (
                  <li
                    key={index}
                    className={`${styles.inquiry} ${styles.inquiry_contact} ${index % 2 === 0 && styles.inquiry_colored}
                      ${i.appointmentsProgram.id === currentTreatmentPlan ? styles.inquiry_selected : ""}`}
                    onClick={() => {
                      showTreatmentDocument(i)
                    }}
                    ref={el => treatmentPlansRefs.current.set(i.appointmentsProgram.id, el)}

                  >
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {i.appointmentsProgram.id}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {`${getPrintDate(new Date(i.appointmentsProgram.createDate))}`}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {i.user.fullName || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {i.appointmentsProgram.name || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                    >
                      {medias.length ? (medias.find(media => media.Id === i.appointmentsProgram.mediasId))?.Name : "not found"}
                    </p>
                    <button
                      className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                    >
                      לקחת
                    </button>
                    <span
                      className={styles.inquiryRRM}
                    />

                  </li>

                )

            )}

        </article>
        {treatmentPlans && treatmentPlans.length > 0 && (
          <ListPagination
            limits={treatmentPlanLimits}
            total={treatmentPlans?.length || 0}
            setLimits={setTreatmentPlanLimits}
            step={limitNumber}
          />
        )}
      </div>
    </section>
  );
}

export default MainPage;
