import styles from "./document.module.css";
import {
    getPrintDate,
    isDateAfterToday,
} from "../../utils/utils";
import React, { useEffect, useState } from "react";
import {
    useLazyGetMainPageInfoQuery,
    useUpdateTherapistTakingTreatmentPlanMutation,
    useGetMigzarsQuery,
    useGetAllAppointmentsInProgramMutation,
    useCancleAppointmentProgramMutation,
    useGetAllOpenTreatmentPlansMutation,
    useGetGendersQuery,
} from "../../services/api/user.api";
import { useNavigate } from "react-router-dom";
import { TCalendarAppointment } from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
    mediasSelector,
    migzarsSelector,
    gendersSelector,
    userSelector,
} from "../../services/selectors/user.selectors";
import Modal from "../modals/modal/Modal";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import { noDataLabel, noPreferenceLabel } from "../../utils/constants";
import { ITreatmentPlan } from "../../types/types";
import ProgramDatesPopup from "../modals/programDatesPopup/programDatesPopup";

interface IDocumentProps {
    openDocument: boolean;
    treatmentPlan: ITreatmentPlan;
    onClose: () => void;
}
function TreatmentDocument({ treatmentPlan, openDocument, onClose }: IDocumentProps) {

    const navigate = useNavigate();

    const therapist = useAppSelector(userSelector);
    const medias = useAppSelector(mediasSelector);
    const migzars = useAppSelector(migzarsSelector);
    const genders = useAppSelector(gendersSelector);

    useGetMigzarsQuery();
    useGetGendersQuery();
    const [refetchMainPageInfo] = useLazyGetMainPageInfoQuery();
    const [takeTreatmentPlan] = useUpdateTherapistTakingTreatmentPlanMutation();
    const [cancleAppointmentProgram] = useCancleAppointmentProgramMutation();
    const [getAllAppointmentsPrograms] = useGetAllOpenTreatmentPlansMutation();
    const [getAllAppointments] = useGetAllAppointmentsInProgramMutation();

    const [updates, setUpdates] = useState<string>(treatmentPlan.appointmentsProgram.comments ?? "");
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openProgramDatesModal, setProgramDatesModal] = useState(false);
    const [programDates, setProgramDates] = useState<TCalendarAppointment[] | null>(null);
    const [isMine, setIsMine] = useState<boolean>(
        treatmentPlan?.appointmentsProgram.therapistsId.toString() === therapist.id.toString());

    useEffect(() => {
        getAllAppointments(treatmentPlan.appointmentsProgram).then(res => {
            if ("data" in res) {
                const mappedTimesInData = res.data.map(app => {
                    return {
                        ...app,
                        startTime: app.startTime?.split(':').slice(0, 2).join(':'),
                        endTime: app.endTime?.split(':').slice(0, 2).join(':')
                    }
                })
                setProgramDates(mappedTimesInData)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treatmentPlan, openDocument])

    useEffect(() => {
        if (treatmentPlan.appointmentsProgram.comments) {
            const parsedUpdates: string = treatmentPlan.appointmentsProgram.comments;
            if (parsedUpdates.length > 0) {
                setUpdates(parsedUpdates);
            }
        } else {
            setUpdates("");
        }
    }, [treatmentPlan]);

    const handleTakingClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (treatmentPlan) {
            takeTreatmentPlan({ ...treatmentPlan.appointmentsProgram, therapistsId: Number(therapist.id) })
                .then((res) => {
                    // @ts-ignore
                    if ("error" in res && res?.error?.originalStatus !== 200) {
                        setOpenErrorModal(true);
                    }
                    else if ("data" in res) {
                        if ("success" in res.data) {
                            setOpenErrorModal(true);
                            if ("message" in res.data)
                                setErrorText("דרישות התוכנית אינן מתאימות לך")
                        } else {
                            setOpenSuccessModal(true);
                            setProgramDates(res.data)
                            setIsMine(true);
                            getAllAppointmentsPrograms(
                                {
                                    companyId: therapist.companyId,
                                    therapistName: therapist.fullName,
                                    therapistId: therapist.id
                                })
                        }
                    }
                })
                .finally(() => {
                    refetchMainPageInfo(therapist.id);
                });
        }

    }

    const cancleAppointment = () => {
        cancleAppointmentProgram(treatmentPlan.appointmentsProgram).then(res => {
            if ("data" in res) {
                setOpenSuccessModal(true);
                getAllAppointmentsPrograms({
                    companyId: therapist.companyId,
                    therapistName: therapist.fullName,
                    therapistId: therapist.id
                })
                onClose();
                setIsMine(false)
            }
            else { setOpenErrorModal(true) }
        })
    }

    return (
        <article
            className={`${styles.document} ${openDocument && styles.document_visible
                }`}
        >
            <button className={styles.documentCloseBtn} onClick={onClose} />
            <h4 className={styles.documentTitle}>תכנית מספר {treatmentPlan?.appointmentsProgram.id}</h4>
            <div className={styles.documentInfo}>
                <p className={styles.documentLabel}>
                    תאריך פתיחה:
                    <span className={styles.documentText}>
                        {treatmentPlan.appointmentsProgram.createDate ? getPrintDate(new Date(treatmentPlan.appointmentsProgram.createDate)) : noDataLabel}
                    </span>
                </p>
                <p className={styles.documentLabel}>
                    אופן הטיפול:
                    <span className={styles.documentText}>
                        {medias.length ? (medias.find(media => media.Id === treatmentPlan.appointmentsProgram.mediasId))?.Name : "לא הוגדר"}
                    </span>
                </p>

                <p className={styles.documentLabel}>
                    מגזר:
                    <span className={styles.documentText}>
                        {migzars.length ? (migzars.find(migzar => migzar.Id === treatmentPlan.appointmentsProgram.migzarId))?.Name ?? noPreferenceLabel : noPreferenceLabel}

                    </span>
                </p>
                <p className={styles.documentLabel}>
                    מגדר:
                    <span className={styles.documentText}>
                        {genders.length ? (genders.find(gender => gender.Id === treatmentPlan.appointmentsProgram.genderId))?.Name ?? noPreferenceLabel : noPreferenceLabel}
                    </span>
                </p>
            </div>
            <div className={styles.documentLinks}>
                <button
                    onClick={() =>
                        navigate(`/users/questions_reports`, {
                            state: {
                                UserDetails: {
                                    UsersId: treatmentPlan.user.id,
                                    // Birth: treatmentPlan.user.,
                                    FirstName: treatmentPlan.user.firstName,
                                    LastName: treatmentPlan.user.lastName,
                                    Phone: treatmentPlan.user.phone
                                },
                                // TherapistDetails: {
                                //     TherapistQuestionnaireId: therapist.question,
                                //     QuestionnaireCompaniesType: document.QuestionnaireCompaniesType,
                                //     QuestionnaireCompaniesId: document.QuestionnaireCompaniesId,
                                //     TherapistCycle: document.TherapistCycle,
                                // },
                            }
                        })
                    }
                    className={`button button_white ${styles.btn} ${styles.btn_type_actionReport
                        } ${!isMine && styles.btn_disabled}`}
                    disabled={!isMine}
                >
                    דוחות שאלונים
                </button>
            </div>
            <h5 className={styles.documentSubTitle}>פרטי התקשרות</h5>
            <p className={styles.documentLabel}>
                מספר משתמש:
                <span className={styles.documentText}>{treatmentPlan.user.id}</span>
            </p>
            <p className={styles.documentLabel}>
                שם משתמש:
                <span className={styles.documentText}>
                    {treatmentPlan.user.fullName || ""}
                </span>
            </p>
            {isMine &&
                <>
                    <p className={styles.documentLabel}>
                        נייד:
                        <span className={styles.documentText}>{treatmentPlan.user.phone}</span>
                    </p>

                    <p className={styles.documentLabel}>
                        אימייל:
                        <span className={styles.documentText}>{treatmentPlan.user.email}</span>
                    </p>
                </>
            }
            <h5 className={styles.documentSubTitle}>הערות</h5>
            {updates ? (
                <p className={styles.documentUpdates}>
                    <span className={styles.documentLabel}>
                        עודכן על ידי: {treatmentPlan.createUserName || noDataLabel}
                    </span>
                    <span className={styles.documentContent}>{updates}</span>
                </p>

            ) : (
                <p className={styles.noDocuments}>טרם עודכן</p>
            )
            }
            {
                !isMine ? (
                    <button
                        disabled={isMine}
                        className={`button button_white ${styles.btn} ${isMine && styles.btn_disabled
                            }`}
                        onClick={(e) => {
                            handleTakingClick(e)
                        }}
                    >
                        לקחת
                    </button>
                ) : (
                    <>
                        <button
                            className={`button button_white ${styles.btn} ${styles.btn_full} `}
                            onClick={() => setProgramDatesModal(true)}
                        >
                            מועדי התוכנית
                        </button>
                        {programDates?.length && isDateAfterToday(programDates[0]?.appointmentDate) &&
                            <button
                                className={`button button_white ${styles.btn} ${styles.btn_red} `}
                                onClick={() => cancleAppointment()}
                            >
                                ביטול התוכנית
                            </button>
                        }
                    </>
                )
            }
            {
                openErrorModal && (
                    <Modal
                        onClose={() => {
                            setOpenErrorModal(false);
                        }}
                    >
                        <ErrorPopup
                            text={errorText ?? null}
                            onClose={() => {
                                setOpenErrorModal(false);
                            }}
                        />
                    </Modal>
                )
            }
            {
                openSuccessModal && (
                    <Modal
                        onClose={() => {
                            setOpenSuccessModal(false);
                        }}
                    >
                        <GeneralSuccessPopup
                            onClose={() => {
                                setOpenSuccessModal(false);
                            }}
                        />
                    </Modal>
                )
            }

            {
                openProgramDatesModal && (
                    <Modal
                        prevencherAnswer
                        onClose={() => {
                            setProgramDatesModal(false);
                        }}
                    >
                        <ProgramDatesPopup
                            treatmentPlan={treatmentPlan}
                            programDates={programDates}
                            onClose={() => {
                                setProgramDatesModal(false);
                            }}
                            onFullClose={() => {
                                setProgramDatesModal(false);
                                onClose();

                            }}
                        />
                    </Modal>
                )
            }

        </article >
    );
}

export default TreatmentDocument;
