import styles from "./actionReportPage.module.css";
import userInfoStyles from "../../components/userInfo/userInfo.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import {
  filterLastOfEachGroup,
  getSelectNote,
  isFormValid,
  noDataLabel,
  TGrouppedQuestionsOptions,
  TLabeledQuestionOption,
  transformServerOptions,
} from "../../utils/constants";
import React, { useEffect, useRef, useState } from "react";
import { ActionMeta, MultiValue } from "react-select";
import Modal from "../../components/modals/modal/Modal";
import SendActionReportSuccessPopup from "../../components/modals/sendActionReportSuccessPopup/sendActionReportSuccessPopup";
import {
  useLazyGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useLazyGetDefaultReportDataQuery,
  useLazyGetMainPageInfoQuery,
} from "../../services/api/user.api";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import Loader from "../../components/loader/loader";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import {
  TDefaultReportData,
  TInsertAllAnswersRequest,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
  userSelector,
} from "../../services/selectors/user.selectors";
import UserInfo from "../../components/userInfo/userInfo";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";

function ActionReportPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {
    TherapistQuestionnaireId,
    QuestionnaireCompaniesType,
    QuestionnaireCompaniesId,
    TherapistCycle,
  } = location.state?.TherapistDetails || {};
  const user = location.state?.UserDetails;

  const therapist = useAppSelector(userSelector);
  const [GetReportQuestionsAndOptions, { data: reportQuestionsAndOptionData, isLoading, isError }]
    = useLazyGetReportQuestionsAndOptionsByQuestionnaireIdQuery();

  const [refreshMainPageInfo] = useLazyGetMainPageInfoQuery();
  const [insertAllAnswers, { isLoading: isSending }] =
    useInsertAllAnswersQuestionsMutation();
  const [getDefaultData] = useLazyGetDefaultReportDataQuery();
  const [reportInitialInfo, setReportInitialInfo] = React.useState<TDefaultReportData | undefined>(
    (!QuestionnaireCompaniesId || !TherapistQuestionnaireId) ? undefined : {
      QuestionnaireCompaniesId: QuestionnaireCompaniesId,
      QuestionnaireId: TherapistQuestionnaireId,
      Cycle: TherapistCycle
    });
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: {
      options: TLabeledQuestionOption[];
      isValid: boolean;
      min: number | null;
      touched: boolean;
      answer?: string;
    };
  }>({});

  useAutosizeTextArea(textAreaRef.current, selectedOptions[109]?.answer || "");

  const sendForm = () => {
    let payload: TInsertAllAnswersRequest[] = [];
    Object.keys(selectedOptions).map((k) =>
      payload.push({
        Id: "0",
        QuestionnaireId: reportInitialInfo?.QuestionnaireId.toString() ?? "0",
        QuestionsId: k,
        UsersId: user?.UsersId?.toString() ?? "",
        Answer: selectedOptions[k].answer ?? "",
        AnswerDate: null,
        QuestionsOptionsIds: selectedOptions[k].options
          .map((option) => option.Id.toString())
          .join(", "),
        Cycle: reportInitialInfo?.Cycle ? reportInitialInfo.Cycle : 1,
      })
    );

    insertAllAnswers({
      answers: payload,
      companyId: therapist.companyId,
      questionnaireCompaniesId: reportInitialInfo?.QuestionnaireCompaniesId ?? -1,
      therapistsId: therapist.id,
    })
      .then((res) => {
        if ("data" in res) {
          setOpenModal(true);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        refreshMainPageInfo(therapist.id);
      });
  };

  useEffect(() => {
    if (!reportInitialInfo) {
      if (user?.UsersId) {
        getDefaultData(user.Guid ?? -1)
          .then(res => {
            if ("data" in res && res.data?.DefaultReportData[0]?.QuestionnaireCompaniesId) {
              setReportInitialInfo({
                ...res.data.DefaultReportData[0],
                Cycle: res.data.DefaultReportData[0].Cycle ? res.data.DefaultReportData[0].Cycle + 1 : 1
              })
            } else {
              setError(true)
            }
          })
      } else {
        setError(true)
      }
    } else {
      GetReportQuestionsAndOptions(reportInitialInfo.QuestionnaireId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInitialInfo]);

  useEffect(() => {
    if (isError) {
      setError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (reportQuestionsAndOptionData) {
      reportQuestionsAndOptionData.forEach((question) =>
        question.Questions.OptionsCountMin
          ? setSelectedOptions((prev) => ({
            ...prev,
            [question.Questions.Id]: {
              options: [],
              isValid: false,
              min: question.Questions.OptionsCountMin,
              touched: false,
            },
          }))
          : false
      );
    }
  }, [reportQuestionsAndOptionData, GetReportQuestionsAndOptions]);

  useEffect(() => {
    if (Object.keys(selectedOptions).length > 0) {
      setFormValid(isFormValid(selectedOptions));
    }
  }, [selectedOptions]);
  const checkedStyles = {
    border: "1px solid  #24a148",
    borderRadius: "4px",
    backgroundColor: "#f7fcf9",
  };

  const formatOptionLabel = (
    option: TGrouppedQuestionsOptions | TLabeledQuestionOption,
    { context }: { context: string }
  ) => {
    if ("GroupId" in option && option.Groups && context === "value") {
      return (
        <div>
          {option.Groups.Name} {option.label}
        </div>
      );
    } else if (context === "menu") {
      return <div>{option.label}</div>;
    } else {
      return <div>{option.label}</div>;
    }
  };

  const onSelectChange = (
    value: MultiValue<TLabeledQuestionOption>,
    { name }: ActionMeta<TLabeledQuestionOption>
  ) => {
    const newValue = filterLastOfEachGroup([...value]);
    const min =
      newValue.length > 0 ? (newValue[0].min ? newValue[0].min : null) : null;
    name &&
      setSelectedOptions((prev) => ({
        ...prev,
        [name]: {
          options: newValue,
          min,
          touched: true,
          isValid:
            prev[name]?.min !== null
              ? (prev[name]?.min ?? 0) <= newValue.length
              : true,
        },
      }));
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h3 className={styles.title}>
        דוח פעולה למשתמש {user?.FirstName} {user?.LastName}
      </h3>
      {user && (
        <div className={styles.userInfo}>
          <UserInfo user={user} />
          <div>
            <div>
              <p
                className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
              >
                מחזור:
              </p>
              <p className={userInfoStyles.user__text}>{reportInitialInfo?.Cycle ? reportInitialInfo.Cycle : 1}</p>
            </div>
            <div>
              <p
                className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
              >
                שאלון:
              </p>
              <p className={userInfoStyles.user__text}>
                {QuestionnaireCompaniesType || noDataLabel}
              </p>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <form className={styles.form}>
          {reportQuestionsAndOptionData &&
            reportQuestionsAndOptionData.length > 0 &&
            reportQuestionsAndOptionData
              .slice()
              .sort((a, b) => a.Questions.Seq - b.Questions.Seq)
              .map((question) => (
                <div
                  key={question.Questions.Id}
                  className={`${styles.formField} ${question.Questions.Id.toString() === "109" &&
                    styles.formField_type_wide
                    }`}
                >
                  <p
                    className={`${styles.text} ${selectedOptions[question.Questions.Id.toString()]
                      ?.touched &&
                      !selectedOptions[question.Questions.Id.toString()]
                        ?.isValid &&
                      styles.note_red
                      }`}
                  >
                    {question.Questions.OptionsCountMin ? "*" : ""}
                    {question.Questions.Desc}
                  </p>
                  {question.Questions.DataTypesId === 1 ? (
                    <CreatableSelect
                      isMulti={true}
                      name={question.Questions.Id.toString()}
                      placeholder={"לחץ כדי לבחור"}
                      options={transformServerOptions(
                        question.QuestionsOptions,
                        question.Questions.OptionsCountMax,
                        question.Questions.OptionsCountMin
                      )}
                      classNamePrefix="select"
                      formatCreateLabel={(inputText) =>
                        ` הוסף חדש: ${inputText} `
                      }
                      formatOptionLabel={formatOptionLabel}
                      onChange={onSelectChange}
                      value={
                        selectedOptions[question.Questions.Id.toString()]
                          ?.options
                      }
                      isOptionDisabled={() => {
                        const count = question?.Questions?.OptionsCountMax;
                        const IsNoScore =
                          question?.Questions?.IsNoScore;
                        const groupId = question?.QuestionsOptions[0].GroupId;
                        if (
                          !selectedOptions[question.Questions.Id.toString()]
                        ) {
                          return false;
                        }
                        return (!groupId || IsNoScore) && count
                          ? selectedOptions[question.Questions.Id.toString()]
                            .options.length >= count
                          : false;
                      }}
                      styles={{
                        control: (baseStyles, state) => {
                          const name = state.selectProps.name;
                          const isValid = name
                            ? selectedOptions[name]?.isValid
                            : false;
                          // const isTouched = name
                          //   ? selectedOptions[name]?.touched
                          //   : false;
                          if (isValid) {
                            return { ...baseStyles, ...checkedStyles };
                          } else return baseStyles;
                        },
                      }}
                    />
                  ) : (
                    <textarea
                      ref={textAreaRef}
                      rows={1}
                      className={`${styles.textarea}`}
                      name={question.Questions.Id.toString()}
                      id={question.Questions.Id.toString()}
                      onChange={(e) => {
                        setSelectedOptions((prev) => ({
                          ...prev,
                          [e.target.name]: {
                            options: [],
                            answer: e.target.value,
                            min: 0,
                            touched: true,
                            isValid: true,
                          },
                        }));
                      }}
                    />
                  )}
                  <p
                    className={`${styles.note} ${selectedOptions[question.Questions.Id.toString()]
                      ?.touched &&
                      !selectedOptions[question.Questions.Id.toString()]
                        ?.isValid &&
                      styles.note_red
                      }`}
                  >
                    {getSelectNote(
                      question.Questions.OptionsCountMin,
                      question.Questions.OptionsCountMax
                    )}
                  </p>
                </div>
              ))}
          <p className={styles.note}>
            !שימו לב שבאפשרויות מקובצות תוכלו לבחור רק אפשרות אחת מתוך קבוצה
          </p>
          <button
            type={"button"}
            className={`button ${styles.submitBtn} ${(!formValid || isSending) && styles.submitBtn_disabled
              }`}
            onClick={sendForm}
            disabled={!formValid || isSending}
          >
            {isSending ? "שליחה" : 'שלח דו"ח'}
          </button>
        </form>
      )}
      {openModal && (
        <Modal
          onClose={() => {
            setOpenModal(false);
            navigate(-1);
          }}
        >
          <SendActionReportSuccessPopup
            onClose={() => {
              setOpenModal(false);
              navigate(-1);
            }}
          />
        </Modal>
      )}
      {error && (
        <Modal onClose={() => setError(false)}>
          <ErrorPopup onClose={() => setError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default ActionReportPage;