import styles from "./questionReportsPage.module.css";
import userInfoStyles from "../../components/userInfo/userInfo.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import {
  IOption,
  RRMListDefault,
  noDataLabel,
  selectCustomStyles,
  testUserEmail,
  testUserEmail2,
} from "../../utils/constants";
import React, { useEffect, useState } from "react";
import QuestionReport from "../../components/questionReport/questionReport";
import { useAppSelector } from "../../services/hooks";
import {
  therapistUsersSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import UserInfo from "../../components/userInfo/userInfo";
import {
  useAddMessageToScoreMutation,
  useGetAllQuestionnairesByUserIdQuery,
  useGetMainPageInfoQuery,
  useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery,
  useLazyGetUsersQuery,
} from "../../services/api/user.api";
import {
  TRRMListEntry,
  TQuestionnaire,
  TQuestionnaireEntry,
  TQuestionnaireQuestion,
  TQuestionnaireScore,
  TTherapistUser,
} from "../../services/types/user.types";
import RrmLink from "../../components/rrmLink/rrmLink";
import { getPrintDate, getTimeFromTimestamp } from "../../utils/utils";
import Select, { SingleValue } from "react-select";
import Modal from "../../components/modals/modal/Modal";
import AddRecommendationPopup from "../../components/modals/addRecommendation/addRecommendation";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";

function QuestionReportsPage() {
  const navigate = useNavigate();

  const location = useLocation();
  const {
    UserDetails,
    TherapistDetails,
  } = location.state;

  const therapist = useAppSelector(userSelector);
  // eslint-disable-next-line eqeqeq
  const therapistUser = useAppSelector(therapistUsersSelector)?.find((u: TTherapistUser) => u.Id == (UserDetails.UsersId))

  const User = "FirstName" in UserDetails ? UserDetails : {
    ...therapistUser,
    UsersId: therapistUser?.Id
  };

  const isTestUser =
    therapist?.email.toLowerCase() === testUserEmail ||
    therapist?.email.toLowerCase() === testUserEmail2;

  useGetMainPageInfoQuery(therapist.id);

  const [getAllTherapistUsers] = useLazyGetUsersQuery();

  useEffect(() => {
    getAllTherapistUsers(therapist.id);
  }, [therapist.id, getAllTherapistUsers]);

  const [cycle, setCycle] = useState<number | null>(null);
  const [QuestionnaireCompaniesId, setQuestionnaireCompaniesId] = useState<number>(TherapistDetails?.QuestionnaireCompaniesId ?? -1);
  const [allReports, setAllReports] = useState<TQuestionnaireEntry[]>([]);
  const [latestActionReport, setLatestActionReport] = useState<
    Record<string, TQuestionnaireEntry[]> | []
  >([]);
  const [latestUserReport, setLatestUserReport] = useState<
    Record<string, TQuestionnaireEntry[]> | []
  >([]);
  const [sources, setSources] = useState<{ QuestionnairId: number, QuestionnairDesc: string }[] | undefined>(undefined);
  const [reportToShow, setReportToShow] = useState<
    | {
      questionnaire: TQuestionnaire;
      results: TQuestionnaireQuestion[];
      score: number;
      rrm: TQuestionnaireScore | null;
      rrmList: TRRMListEntry[];
    }
    | undefined
  >(undefined);
  const [actionReport, setActionReport] = useState<
    | {
      questionnaire: TQuestionnaire;
      results: TQuestionnaireQuestion[];
      score: number;
      rrm: TQuestionnaireScore | null;
      rrmList: TRRMListEntry[];
    }
    | undefined
  >(undefined);
  const [reportsDateOptions, setReportsDateOptions] = useState<IOption[]>([]);
  const [actionReportsDateOptions, setActionReportsDateOptions] = useState<
    IOption[]
  >([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [addMessageToTheScore] = useAddMessageToScoreMutation();
  const [messagesByScore, setMessagesByScore] = useState<TQuestionnaireScore[]>(
    []
  );

  const closeAllModals = () => {
    setOpenModal(false);
    setOpenModalSuccess(false);
    setOpenModalError(false);
  };
  const [getMultipleReportsByUserIdAndCycle] =
    useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery();

  useEffect(() => {
    cycle &&
      getMultipleReportsByUserIdAndCycle({
        UsersId: User.UsersId?.toString(),
        Cycle: cycle,
        QuestionnaireCompaniesId: QuestionnaireCompaniesId,
        CompanyId: therapist.companyId,
      }).then((res) => {
        if ("data" in res && res.data?.UserQuestionnaire) {
          const scoreDataUR =
            res.data.RRMAndScore?.find(
              (item) =>
                item.QuestionnaireId ===
                res.data?.UserQuestionnaire?.Questionnaire?.Id &&
                item.QuestionnaireCompaniesId === QuestionnaireCompaniesId &&
                item.Cycle === cycle
            ) ?? null;
          const scoreDataTR =
            res.data.RRMAndScore?.find(
              (item) =>
                item.QuestionnaireId ===
                res.data?.TherapistQuestionnaire?.Questionnaire?.Id &&
                item.QuestionnaireCompaniesId === QuestionnaireCompaniesId &&
                item.Cycle === cycle
            ) ?? null;

          scoreDataUR ?
            setReportToShow({
              questionnaire: res.data.UserQuestionnaire.Questionnaire,
              results: res.data.UserQuestionnaire.Questions,
              score: scoreDataUR.Score || 0,
              rrm: scoreDataUR,
              rrmList: res.data.RRMList,
            })
            : scoreDataTR && setReportToShow(undefined);
          scoreDataTR
            ? setActionReport({
              questionnaire: res.data.TherapistQuestionnaire.Questionnaire,
              results: res.data.TherapistQuestionnaire.Questions,
              score: scoreDataTR.Score || 0,
              rrm: scoreDataTR,
              rrmList: res.data.RRMList,
            })
            : setActionReport(undefined);
          setMessagesByScore(res.data.RRMAndScore || []);

          const tempSources = [];
          scoreDataTR && tempSources.push({
            QuestionnairId: res.data.TherapistQuestionnaire.Questionnaire.Id,
            QuestionnairDesc: res.data.TherapistQuestionnaire.Questionnaire.Desc
          });
          scoreDataUR && tempSources.push({
            QuestionnairId: res.data.UserQuestionnaire.Questionnaire.Id,
            QuestionnairDesc: res.data.UserQuestionnaire.Questionnaire.Desc
          })
          setSources(tempSources);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cycle,
  ]);
  const { data } =
    // @ts-ignore
    useGetAllQuestionnairesByUserIdQuery({
      id: User?.UsersId?.toString() ?? "",
    });

  useEffect(() => {
    if (data) {
      setAllReports([
        ...data.QuestionnairesUsers,
        ...data.QuestionnairesTherapists,
      ]);
      const latestReportUsersGroup = groupByQuestionnaireCompaniesId(
        data.QuestionnairesUsers
      );

      const latestReportTherapistsGroup = groupByQuestionnaireCompaniesId(
        data.QuestionnairesTherapists
      );
      latestReportUsersGroup && setLatestUserReport(latestReportUsersGroup);
      latestReportTherapistsGroup &&
        setLatestActionReport(latestReportTherapistsGroup);

      // eslint-disable-next-line array-callback-return
      Object.entries(latestReportUsersGroup).map(([questionnaireId, que]: [string, TQuestionnaireEntry[]]) => {
        setReportsDateOptions((prev) => [
          ...prev,
          ...que.map((qu) => ({
            label: `${getPrintDate(
              new Date(qu.Timestamp),
              true
            )} (${getTimeFromTimestamp(new Date(qu.Timestamp))}) (${qu.Cycle})`,
            value: qu.Timestamp,
            QuestionnaireCompaniesId: qu.QuestionnaireCompaniesId
          })),
        ]);
      });

      Object.entries(latestReportTherapistsGroup).map(
        // eslint-disable-next-line array-callback-return
        ([questionnaireId, que]: [string, TQuestionnaireEntry[]]) => {
          setActionReportsDateOptions((prev) => [
            ...prev,
            ...que.map((qu) => ({
              label: `${getPrintDate(
                new Date(qu.Timestamp),
                true
              )} (${getTimeFromTimestamp(new Date(qu.Timestamp))}) (${qu.Cycle
                })`,
              value: qu.Timestamp,
              QuestionnaireCompaniesId: qu.QuestionnaireCompaniesId
            })),
          ]);
        }
      );
    }
  }, [data]);

  const groupByQuestionnaireCompaniesId = (
    items: TQuestionnaireEntry[]
  ): Record<string, TQuestionnaireEntry[]> => {
    const grouped = items.reduce<Record<string, TQuestionnaireEntry[]>>(
      (acc, item) => {
        if (!acc[item.QuestionnaireCompaniesId]) {
          acc[item.QuestionnaireCompaniesId] = [];
        }
        acc[item.QuestionnaireCompaniesId].push(item);
        return acc;
      },
      {}
    );
    Object.keys(grouped).forEach((key) => {
      grouped[key].sort((a, b) => b.Cycle - a.Cycle);
    });
    return grouped;
  };


  const onSelectChange = (newValue: SingleValue<IOption>) => {
    const newCycle = allReports.filter(qu => qu.QuestionnaireCompaniesId === QuestionnaireCompaniesId).find(
      (r) => r.Timestamp === newValue?.value
    )?.Cycle;
    if (newCycle) {
      setCycle(newCycle);
    }
  };

  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => {
          if (reportToShow || actionReport) {
            setActionReport(undefined);
            setReportToShow(undefined);
            setCycle(null)
          }
          else navigate(-1)
        }}
        position={"center"}
      />
      <h2 className={`title`}>
        {(reportToShow || actionReport)
          ? ` דוחות שאלונים`
          : ` דוחות שאלונים עבור: ${User.FirstName} ${User.LastName}`}
      </h2>
      {(reportToShow || actionReport) ? (
        <article className={styles.table}>
          {User && (
            <div className={styles.table__header}>
              <UserInfo user={User} />
              <div className={styles.table__columns}>
                {isTestUser && (
                  <div>
                    <p
                      className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
                    >
                      מקור:
                    </p>
                    <ul className={styles.sources}>
                      {sources &&
                        sources.map((s, index) => (
                          <li key={index} className={userInfoStyles.user__text}>
                            {s.QuestionnairDesc}
                            {index < sources.length - 1 ? "," : ""}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                <div className={styles.centeredFlex}>
                  <button
                    className={`${styles.btn} hover`}
                    onClick={() => setOpenModal(true)}
                  >
                    להוסיף המלצה
                  </button>

                  {messagesByScore.length > 0 && (
                    <p
                      className={`${userInfoStyles.user__label} ${userInfoStyles.form__subtitle}`}
                    >
                      &#10003; המלצה נשלחה
                    </p>
                  )}
                </div>
                <div></div>

              </div>
            </div>
          )}
          <div className={styles.content}>
            <h2 className={styles.header}>
              <div className={styles.headerContainer}>
                <Select
                  name={"reportDate"}
                  className={styles.select}
                  options={reportsDateOptions.filter(qu => qu.QuestionnaireCompaniesId === QuestionnaireCompaniesId)}
                  value={
                    reportsDateOptions.find(
                      (r) =>
                        r.value ===
                        allReports.filter(qu => qu.QuestionnaireCompaniesId === QuestionnaireCompaniesId).find(
                          (r) =>
                            (r.QuestionnaireId ===
                              reportToShow?.questionnaire.Id
                            ) && r.Cycle === cycle
                        )?.Timestamp
                    ) || null
                  }
                  onChange={onSelectChange}
                  styles={selectCustomStyles}
                  isDisabled={reportsDateOptions.length < 1}
                  placeholder={noDataLabel}
                />
                <p className={styles.headerTitle}>
                  {reportToShow ? reportToShow.questionnaire.Desc : actionReport ? actionReport.questionnaire.Desc : noDataLabel}
                </p>
                <div className={styles.headerRrm}>
                  <RrmLink
                    RRMScore={reportToShow?.rrm?.RRM ?? 0}
                    score={reportToShow?.score ?? 0}
                    scoreList={
                      (isTestUser && reportToShow) ? reportToShow.rrmList : RRMListDefault
                    }
                    additionalStyles={{
                      position: "static",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <p className={styles.headerText}>
                  {(isTestUser && reportToShow)
                    ? `ציון שאלון:
                  ${reportToShow.score}`
                    : ""}
                </p>
              </div>
              <div className={styles.headerContainer}>
                <Select
                  name={"actionReportDate"}
                  className={styles.select}
                  options={actionReportsDateOptions.filter(qu => qu.QuestionnaireCompaniesId === QuestionnaireCompaniesId)}
                  onChange={onSelectChange}
                  placeholder={noDataLabel}
                  value={
                    actionReportsDateOptions.find(
                      (r) =>
                        r.value ===
                        allReports.filter(qu => qu.QuestionnaireCompaniesId === QuestionnaireCompaniesId).find(
                          (r) =>
                            r.QuestionnaireId ===
                            actionReport?.questionnaire.Id &&
                            r.Cycle === cycle
                        )?.Timestamp
                    ) || null
                  }
                  styles={selectCustomStyles}
                  isDisabled={actionReportsDateOptions.length < 1}
                />
                <p className={styles.headerTitle}>
                  {actionReport?.questionnaire.Desc}
                </p>
                <div className={styles.headerRrm}>
                  {actionReport && actionReport.score > 0 && (
                    <RrmLink
                      RRMScore={actionReport.rrm?.RRM ?? 0}
                      score={actionReport.score}
                      scoreList={
                        (isTestUser && reportToShow) ? reportToShow.rrmList : RRMListDefault
                      }
                      additionalStyles={{
                        position: "static",
                        backgroundColor: "transparent",
                      }}
                    />
                  )}
                </div>

                <p className={styles.headerText}>
                  גורם מדווח :{actionReport?.rrm?.TherapistsName || noDataLabel}
                </p>
                <p className={styles.headerText}>
                  {isTestUser && actionReport
                    ? `ציון שאלון:
                  ${actionReport.score}`
                    : ""}
                </p>
              </div>
            </h2>
            <div className={styles.reports_table}>
              {reportToShow && reportToShow.score > 0 && (
                <QuestionReport isTestUser={isTestUser} report={reportToShow} isRightSide={true} />
              )}
              {actionReport && actionReport.score > 0 && (
                <QuestionReport isTestUser={isTestUser} report={actionReport} isRightSide={false} />
              )}
            </div>
            <textarea
              className={`input ${styles.textarea}`}
              placeholder={"המלצות לגורם מטפל"}
            />
          </div>
        </article>
      ) : (
        <div className={`${styles.editOtionsTable} ${styles.editOptions}`}>
          <ul className={styles.editOptionRight}>
            {latestUserReport.length !== 0 &&
              Object.entries(latestUserReport).map(
                ([questionnaireId, Report]) => {
                  const index = (!TherapistDetails?.QuestionnaireCompaniesId ||
                    // eslint-disable-next-line eqeqeq
                    Report[0].QuestionnaireCompaniesId != TherapistDetails.QuestionnaireCompaniesId) ? null
                    // eslint-disable-next-line eqeqeq
                    : Report.findIndex(re => re.Cycle == TherapistDetails?.TherapistCycle);

                  return <li
                    key={questionnaireId}
                    className={`${styles.editOption} ${(index != null && index !== -1) && styles.editOptionColor} hover`}
                    onClick={() => {
                      setCycle(Report[index ?? 0].Cycle);
                      setQuestionnaireCompaniesId(Report[index ?? 0].QuestionnaireCompaniesId)
                    }}
                  >
                    {Report[index ?? 0].Desc} ( מחזור {Report[index ?? 0].Cycle})
                    <span className={styles.editOptionComment}>
                      {getPrintDate(new Date(Report[index ?? 0].Timestamp))} (
                      {getTimeFromTimestamp(new Date(Report[index ?? 0].Timestamp))})
                    </span>
                  </li>
                }
              )}
          </ul>
          <ul className={styles.editOptionLeft}>
            {latestActionReport.length !== 0 &&
              Object.entries(latestActionReport).map(
                ([questionnaireId, Report]) => {
                  const index = (!TherapistDetails?.QuestionnaireCompaniesId ||
                    // eslint-disable-next-line eqeqeq
                    Report[0].QuestionnaireCompaniesId != TherapistDetails.QuestionnaireCompaniesId) ? null
                    // eslint-disable-next-line eqeqeq
                    : Report.findIndex(re => re.Cycle == TherapistDetails?.TherapistCycle);
                  return index !== -1 ? <li
                    key={questionnaireId}
                    className={`${styles.editOption} ${(index != null && index !== -1) && styles.editOptionColor} hover`}
                    onClick={() => {
                      setCycle(Report[index ?? 0].Cycle);
                      setQuestionnaireCompaniesId(Report[index ?? 0].QuestionnaireCompaniesId);
                    }}
                  >
                    {Report[index ?? 0].Desc} ( מחזור {Report[index ?? 0].Cycle})
                    <span className={styles.editOptionComment}>
                      {getPrintDate(new Date(Report[index ?? 0].Timestamp))} (
                      {getTimeFromTimestamp(new Date(Report[index ?? 0].Timestamp))})
                    </span>
                  </li>
                    : <li
                      className={`${styles.emptyOption}`}
                      key={questionnaireId}
                    ></li>
                }
              )}
            {!latestUserReport && !latestActionReport && (
              <p className={styles.headerText}>לא נמצאו דוחות עבור משתמש זה</p>
            )}
          </ul>
        </div>
      )
      }
      {
        openModal && User && (
          <Modal onClose={() => setOpenModal(false)}>
            <AddRecommendationPopup
              user={UserDetails ?? User as any}
              addMessageToScore={(form: { Title: string; Desc: string }) => {
                sources &&
                  addMessageToTheScore({
                    QuestionnaireIds: sources.map(s => s.QuestionnairId).join(","),
                    MessageId: "0",
                    Title: form.Title,
                    Desc: form.Desc,
                    Score: reportToShow?.score.toString() ?? "0",
                    UsersId: User.UsersId.toString(),
                    Cycle: cycle,
                  })
                    .then((res) => {
                      if (
                        "data" in res ||
                        // @ts-ignore
                        ("error" in res && res.error.originalStatus === 200)
                      ) {
                        setOpenModal(false);
                        setOpenModalSuccess(true);
                      } else {
                        setOpenModal(false);
                        setOpenModalError(true);
                      }
                    })
                    .finally(
                      () =>
                        cycle &&
                        getMultipleReportsByUserIdAndCycle({
                          UsersId: User.UsersId.toString(),
                          Cycle: cycle,
                          QuestionnaireCompaniesId:
                            QuestionnaireCompaniesId,
                          CompanyId: therapist.companyId,
                        }).then((res) => {
                          const newMessageList = res.data?.RRMAndScore || [];
                          setMessagesByScore(newMessageList);
                        })
                    );
              }}
            />
          </Modal>
        )
      }
      {
        openModalSuccess && (
          <Modal onClose={closeAllModals}>
            <GeneralSuccessPopup onClose={closeAllModals} />
          </Modal>
        )
      }
      {
        openModalError && (
          <Modal onClose={closeAllModals}>
            <ErrorPopup onClose={closeAllModals} />
          </Modal>
        )
      }
    </article >
  );
}

export default QuestionReportsPage;
