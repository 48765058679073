import mainContentLayoutStyles from "../../components/mainContentLayout/mainContentLayout.module.css";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { mobileWidth } from "../../utils/constants";
import Sidebar from "../../components/sidebar/sidebar";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Chat from "../../components/chat/chat";
import { Category } from "../../types/types";
import { menuCategories } from "../../utils/categories";
import { useAppSelector } from "../../services/hooks";
import {
  allConnectedUsersSelector,
  chatConnectionSelector,
  chatUsersSelector,
} from "../../services/selectors/chat.selector";
import { IUserChatData } from "../../services/types";

interface IChatPageProps {
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function ChatPage({ changeCategory }: IChatPageProps) {
  const [activeUser, setActiveUser] = useState<IUserChatData | undefined>(
    undefined
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const mobile = useMediaQuery(mobileWidth);
  const connection = useAppSelector(chatConnectionSelector);
  const chatUsers = useAppSelector(chatUsersSelector);
  const allConnectedUsers = useAppSelector(allConnectedUsersSelector);

  useEffect(() => {
    if (connection) {
      connection
        .invoke("FetchChatDataForUsers", allConnectedUsers)
        .catch((err) =>
          console.error("Error calling FetchChatDataForUsers:", err)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, allConnectedUsers]);

  useEffect(() => {
    if (!activeUser?.userId) {
      setActiveUser(
        chatUsers.find((u) => u.appointment && !u.appointment.EndTime)
      );
    } else {
      setActiveUser((prev) => chatUsers.find((u) => u.userId === prev?.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUsers]);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mainContentLayoutStyles.main_chat
      } ${!openMenu && mainContentLayoutStyles.main_chat_small} `}
    >
      {/*<Notifications showNotifications={showNotifications} />*/}
      <div
        className={`${mainContentLayoutStyles.overlay} ${
          mobile && openMenu ? mainContentLayoutStyles.overlay_visible : ""
        }`}
      />
      <section
        className={`${mainContentLayoutStyles.mainContent} ${mainContentLayoutStyles.mainContent_chat}`}
        onClick={hideNotifications}
      >
        <div
          className={`${mainContentLayoutStyles.header} ${mainContentLayoutStyles.header_chat}`}
        >
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {menuCategories[Category.Messages]}
          </h1>
          {/*<button*/}
          {/*  className={`${mainContentLayoutStyles.mainContent__bell} ${*/}
          {/*    showNotifications &&*/}
          {/*    mainContentLayoutStyles.mainContent__bell_invisible*/}
          {/*  }`}*/}
          {/*  onClick={() => {*/}
          {/*    setShowNotifications(!showNotifications);*/}
          {/*  }}*/}
          {/*/>*/}
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        <Chat
          openMenu={openMenu}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />
      </section>
      {!mobile && (
        <ChatSideBar
          openMenu={openMenu}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />
      )}
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={Category.Messages}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
    </main>
  );
}

export default ChatPage;
