import loginStyles from "../../../pages/login/login.module.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginWithCodeMutation } from "../../../services/api/auth.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { useActions } from "../../../services/hooks/useActions";
import { useFormik } from "formik";
import { loginSMSValidationSchema } from "../../../utils/validation";
import { rolesSelector } from "../../../services/selectors/user.selectors";
import { IRole } from "../../../services/types";
import { getCookie, setCookie } from "../../../utils/token";

function LoginSmsForm() {
  const navigate = useNavigate();
  const { setPhone, setRoleId } = useActions();
  const loginError =
    useAppSelector(errorUserSelector) || "משהו השתבש, אנא נסה שוב מאוחר יותר";
  const options = useAppSelector(rolesSelector);

  const [loginWithCode, { status, isError }] = useLoginWithCodeMutation();

  const formik = useFormik({
    initialValues: {
      phone: "",
      roleId: "",
    },
    validationSchema: loginSMSValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log("disabled - usersId required");
      // Handle form submission
      // loginWithCode(formik.values).then((response) => {
      //   if ("data" in response && response.data.success) {
      //     navigate("enter_code");
      //     setPhone(values.phone);
      //     setRoleId(values.roleId);
      //     setCookie("roleId", values.roleId.toString(), { expires: 1000 * 60 * 60 * 24 * 7 });
      //   }
      // });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (options?.length) {
      const savedRoleId = getCookie("roleId");
      if (
        savedRoleId &&
        options.some((opt: IRole) => opt.id === Number(savedRoleId))
      ) {
        formik.setFieldValue("roleId", savedRoleId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <form onSubmit={formik.handleSubmit} className={loginStyles.login__form}>
      <p className={loginStyles.login__formText}>מסרון ישלח למספר נייד שלך</p>
      <label htmlFor="name" className={loginStyles.login__label}>
        מספר נייד שלי
      </label>
      <input
        type="text"
        className={`${loginStyles.login__input}  ${
          formik.errors.phone && loginStyles.login__input_invalid
        }`}
        name="phone"
        required={true}
        placeholder="מספר נייד"
        value={formik.values.phone}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <select
        id="roleId"
        name="roleId"
        value={formik.values.roleId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`${loginStyles.login__input} ${loginStyles.login__select} ${
          formik.errors.roleId && loginStyles.login__input_invalid
        }`}
      >
        <option
          className={`${loginStyles.login__select__default}`}
          value=""
          disabled
        >
          תחום התמחות{" "}
        </option>
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
      {formik.errors.phone && (
        <p className={`${loginStyles.login__invalid_message}`}>
          {formik.errors.phone}
        </p>
      )}
      {isError && (
        <p className={`${loginStyles.login__invalid_message}`}>{loginError}</p>
      )}
      <p className={loginStyles.login__restorePasswordLink}>
        לא קיבלת הודעה? שלח שוב
      </p>
      <button
        type="submit"
        disabled={
          !formik.values.phone ||
          !formik.isValid ||
          formik.isSubmitting ||
          status === "pending"
        }
        className={`button ${loginStyles.login__button} ${
          !formik.values.phone ||
          !formik.isValid ||
          formik.isSubmitting ||
          status === "pending"
            ? loginStyles.login__button_disabled
            : "hover"
        }`}
      >
        {status === "pending" ? "שליחה" : "כניסה"}
      </button>
    </form>
  );
}

export default LoginSmsForm;
