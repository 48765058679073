import styles from "./editAvailabilityPage.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { useAppSelector } from "../../services/hooks";
import { availabilitySelector, therapistCompanyIdSelector, therapistIdSelector } from "../../services/selectors/user.selectors";
import Modal from "../../components/modals/modal/Modal";
import EditAvailabilityPopup from "../../components/modals/editAvailabilityPopup/editAvailabilityPopup";
import { useGetTherapistAvailabilityMutation } from "../../services/api/user.api";
import { IAvailability } from "../../types/types";

function EditAvailabilityMonthlyPage() {
  const navigate = useNavigate();
  const [activeDate, setActiveDate] = useState<null | Date>(null);
  const therapistId = useAppSelector(therapistIdSelector)
  const therapistCompanyId = useAppSelector(therapistCompanyIdSelector)
  const availability = useAppSelector(availabilitySelector);

  const [getAvailability] = useGetTherapistAvailabilityMutation()

  useEffect(() => {
    if (!availability.length)
      getAvailability({ CompanyId: therapistCompanyId, TherapistId: Number(therapistId) })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupRangesByDay = (ranges: IAvailability[]) => {
    return ranges.reduce<Record<string, IAvailability[]>>((acc, range) => {
      
      if (range.specificDate) {
        const dayNumber = `${new Date(range.specificDate).getDate()}`;
        if (!acc[dayNumber]) {
          acc[dayNumber] = [];
        }
        acc[dayNumber].push(range);
      }
      return acc;
    }, {});
  };
  const availabilityGroup = groupRangesByDay(availability.filter(d => d.specificDate))

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"לחזור"}
        onClick={() => navigate("/calendar")}
        position={"center"}
      />
      <h2 className={styles.title}>זמינות שלי</h2>
      <p className={`${styles.text} ${[styles.text_position_center]}`}>
        כדי להגדיר שעות זמינות לחץ על יום מסוים
      </p>
      <div className={styles.calendarSection}>
        <Calendar
          calendarType={"hebrew"}
          locale={"he"}
          onClickDay={setActiveDate}
          value={activeDate}
          prev2Label={null}
          next2Label={null}
          minDate={new Date()}
          tileDisabled={({ date }) => date.getDay() === 6}
          tileContent={({ date }) => {
            const currentDate = availabilityGroup[date.getDate()]
            if (currentDate && currentDate[0].statusId && currentDate[0].isAvailable) {
              return (
                <div className={styles.circleContainer}>
                  <span className={styles.circle} />
                </div>
              );
            } else {
              return null;
            }
          }}
        />
      </div>
      {activeDate !== null &&
        (
          <Modal
            onClose={() => {
              setActiveDate(null);
            }}
          >
            <EditAvailabilityPopup
              day={availabilityGroup[activeDate.getDate()]??[]}
              dayId={activeDate.getDay()}
              onClose={() => {
                setActiveDate(null);
              }}
              specificDate={activeDate}
            />
          </Modal>
        )}
    </section>
  );
}

export default EditAvailabilityMonthlyPage;
