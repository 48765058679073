import loginStyles from "./login.module.css";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import { authApi, useLoginGoogleMutation } from "../../services/api/auth.api";
import Tooltip from "../../components/tooltip/tooltip";
import { useGoogleLogin } from "@react-oauth/google";
import { TLoginGoogleRequest } from "../../services/types/auth.types";
import { userSelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useLazyGetUserQuery } from "../../services/api/user.api";
import { getCookie } from "../../utils/token";

function LoginSmsCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/main";
  const therapist = useAppSelector(userSelector);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const initialState = ["", "", "", ""];
  const [nums, setNums] = useState(initialState);
  const [showSendCodeTooltip, setShowSendCodeTooltip] = useState(false);

  //waiting for new logic
  const [loginWithCode, { isError: isSendAgainError }] =
    authApi.useLoginWithCodeMutation();
  const [sendTokenToServer, { isError: isSendTokenError }] =
    useLoginGoogleMutation();
  const [getUser] = useLazyGetUserQuery();

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendTokenToServer(codeResponse as TLoginGoogleRequest);
    },
  });

  useEffect(() => {
    if (!therapist.id) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sendCode, { isError }] = authApi.useSendCodeMutation();
  const loginError = useAppSelector(errorUserSelector);

  // Function to focus on the next input
  const focusNextInput = (index: number) => {
    const nextIndex = index + 1;
    const nextInputRef = inputRefs[nextIndex];
    if (nextInputRef && nextInputRef.current) {
      nextInputRef.current.focus();
    }
  };

  const handleNumChange = async (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    focusNextInput(index);
    const limit = 1;
    try {
      await setNums(
        nums.map((num, index) =>
          index.toString() === e.target.id
            ? e.target.value.slice(0, limit)
            : num
        )
      );
    } catch (err) {}
  };

  const handleSubmit = () => {
    const savedRoleId = getCookie("roleId");
    sendCode({
      code: nums.join(""),
      usersId: therapist.id,
      roleId: savedRoleId || 2,
      email: therapist.email,
      password: therapist.password || "",
    }).then((response) => {
      if ("data" in response && response.data.success) {
        setNums(initialState);
        getUser()
          .then(() => {
            navigate(from, { replace: true });
          })
          .catch((error) => {
            console.error("Error fetching user:", error);
          });
      } else {
        setNums(initialState);
        focusNextInput(-1);
      }
    });
  };
  useEffect(() => {
    if (nums.every((num) => num !== "")) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nums]);

  //disabled - waiting for new logic
  const sendCodeAgain = () => {
    // setShowSendCodeTooltip(true);
    // loginWithCode({ phone: therapist.phone, roleId: therapist.roleId }).then(
    //   (response) => {
    //     if ("data" in response && response.data.success) {
    //     }
    //   }
    //   );
    //   setTimeout(() => {
    //     setShowSendCodeTooltip(false);
    //   }, 1500);
  };

  return (
    <article className={loginStyles.login}>
      <BackArrowButton text={"למסך הקודם"} onClick={() => navigate("/")} />
      <h1
        className={`${loginStyles.login__title} ${loginStyles.login__title_sms}`}
      >
        התגעגענו
      </h1>
      <p
        className={`${loginStyles.login__text} ${loginStyles.login__text_small}`}
      >
        שלחנו קוד אימות בSMS לנייד שמסתיים ב{therapist.phone.slice(-3)}
      </p>
      <label htmlFor="num1" className={loginStyles.login__enterCodeLabel}>
        הקלד קוד אימות
      </label>
      <ul className={loginStyles.login__enterCode}>
        {nums.map((num, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            onChange={(e) => handleNumChange(e, index)}
            type="number"
            name={index.toString()}
            id={index.toString()}
            className={loginStyles.login__number}
            placeholder="__"
            value={nums[index]}
            autoFocus={index === 0}
          />
        ))}
      </ul>

      {(isError || isSendAgainError || isSendTokenError) && (
        <p className={`${loginStyles.login__invalid_message}`}>{loginError}</p>
      )}
      <p className={loginStyles.login__sendAgain}>
        לא קיבלת קוד?
        <span
          className={`${loginStyles.login__sendAgain_underlined} hover`}
          onClick={sendCodeAgain}
        >
          שלח מחדש
          <Tooltip isShown={showSendCodeTooltip}>שלחנו שוב</Tooltip>
        </span>
      </p>

      <div className={loginStyles.login__bottomBar}>
        <p className={loginStyles.login__alternative}>או</p>
        <div className={loginStyles.login__social}>
          <ul
            className={`${loginStyles.login__socialList} ${loginStyles.login__socialList_column}`}
          >
            <li>
              <button
                type="button"
                onClick={() => loginWithGoogle()}
                className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_long} hover`}
              >
                <span
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_small} ${loginStyles.login__socialBtn_google}`}
                />
                התחבר דרך גוגל
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_long} hover`}
              >
                <span
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_small} ${loginStyles.login__socialBtn_apple}`}
                />
                התחבר דרך אפל
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_long} hover`}
              >
                <span
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_small} ${loginStyles.login__socialBtn_facebook}`}
                />
                התחבר דרך פייסבוק
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p className={loginStyles.login__conditions}>שימוש באפליקציה תלוי</p>
        <Link className={loginStyles.login__link} to="/terms">
          בתקנון השימוש
        </Link>
      </div>
    </article>
  );
}

export default LoginSmsCode;
