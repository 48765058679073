import { IUser } from "../types/types";
import { therapistPOCQuestionnaireName } from "./constants";
// import {
//   prescriptionsExample,
//   therapist1,
//   therapist2,
//   therapist3,
// } from "./therapists";
// import { defaultSettings } from "./constants";

// export const testData: IUser = {
//   id: "123456",
//   firstName: "יעל",
//   lastName: "מרדכי",
//   fullName: "יעל מרדכי",
//   account: "4584",
//   email: "test@bpreven.com",
//   password: "78ushgd00ghdd1gmu7km",
//   phone: "0544832880",
//   address: "",
//   plan: "basic",
//   card: "3456",
//   unreadMessages: 3,
//   settings: defaultSettings,
//   prescriptions: prescriptionsExample,
//   invoices: invoicesExample,
//   avatar:
//     "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60",
//   therapists: [therapist1, therapist2, therapist3],
//   appointments: [
//     { id: 0, therapist: therapist1, date: new Date("June 20, 2023 12:00:00") },
//     {
//       id: 1,
//       therapist: therapist2,
//       date: new Date("June 20, 2023 13:15:00"),
//     },
//   ],
//   videos: [],
// };

export const users: Partial<IUser>[] = [
  {
    id: "1",
    firstName: "צחי",
    lastName: "חזן",
    account: "1001",
    passport: null,
    roleId: 1,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: "123451",
    birth: null,
    fullName: "צחי חזן",
    unreadMessages: 2,
    online: true,
    messages: [],
  },
  {
    id: "2",
    firstName: "koket",
    lastName: "hazan",
    account: undefined,
    passport: null,
    roleId: 2,
    roles: null,
    phone: "0505913817",
    email: "koket@gmail.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "koket hazan",
  },
  {
    id: "3",
    firstName: "Kodsfsfko",
    lastName: "hazan",
    account: undefined,
    passport: "036450310",
    roleId: 3,
    roles: null,
    phone: "0505913817",
    email: "kok4et@gmail.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "Kodsfsfko hazan",
  },
  {
    id: "4",
    firstName: "ילנה",
    lastName: "Soloveva",
    account: undefined,
    passport: "123456789",
    roleId: 1,
    roles: null,
    phone: "0528664603",
    email: "test@bpreven.com",
    planId: 3,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar:
      "https://media.istockphoto.com/id/1354205521/photo/portrait-of-confident-indian-it-programmer-working-on-desktop-computer-professional-female.webp?b=1&s=170667a&w=0&k=20&c=pcCEhroPcso-lUEqhwGxPQ3BCAVnUKmljzZadJ1dqPk=",
    address: "אשקלון 3, 13",
    birth: null,
    fullName: "ילנה Soloveva",
  },
  {
    id: "5",
    firstName: "Ann",
    lastName: "jhkshf",
    account: undefined,
    passport: "222222222",
    roleId: 1,
    roles: null,
    phone: "050-6777777",
    email: "ya@ya.ru",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "Ann jhkshf",
  },
  {
    id: "6",
    firstName: "koket",
    lastName: "hazan",
    account: undefined,
    passport: "036450310",
    roleId: 1,
    roles: null,
    phone: "0505913817",
    email: "koke2t@gmail.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "koket hazan",
  },
  {
    id: "7",
    firstName: "saasd",
    lastName: "asdasdas",
    account: undefined,
    passport: "111111111",
    roleId: 1,
    roles: null,
    phone: "050-8888888",
    email: "y@y.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "saasd asdasdas",
  },
  {
    id: "8",
    firstName: "uiuyiuy",
    lastName: "iuyiuyiuy",
    account: undefined,
    passport: "687687687",
    roleId: 1,
    roles: null,
    phone: "050-8888888",
    email: "yutt@ya.ru",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "uiuyiuy iuyiuyiuy",
  },
  {
    id: "9",
    firstName: "Elena",
    lastName: "ewqeqw",
    account: undefined,
    passport: "111111111",
    roleId: 1,
    roles: null,
    phone: "050-1111111",
    email: "ty@ty.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1675056551963-a9283863fa22?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0M3x8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "Elena ewqeqw",
  },
  {
    id: "10",
    firstName: "hjhjhjhjjj",
    lastName: "ewqeqwkih",
    account: undefined,
    passport: "398076543",
    roleId: 1,
    roles: null,
    phone: "050-6789567",
    email: "giu@giu.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1982-02-12T00:00:00",
    fullName: "hjhjhjhjjj ewqeqwkih",
  },
  {
    id: "11",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: "666666666",
    roleId: 1,
    roles: null,
    phone: "050-8888880",
    email: "uy@t",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-09-12T00:00:00",
    fullName: "Elena Soloveva",
  },
  {
    id: "12",
    firstName: "jjjjjjjjjjjj",
    lastName: "jjjjj",
    account: undefined,
    passport: "888888888",
    roleId: 1,
    roles: null,
    phone: "050-i990000",
    email: "1@ya.ru",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-11-28T00:00:00",
    fullName: "jjjjjjjjjjjj jjjjj",
  },
  {
    id: "13",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: "111111110",
    roleId: 1,
    roles: null,
    phone: "050-8664603",
    email: "seaver@yandex.ru",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-11-12T00:00:00",
    fullName: "Elena Soloveva",
  },
  {
    id: "14",
    firstName: "gsutuyt",
    lastName: "ytuyuyt",
    account: undefined,
    passport: "767576566",
    roleId: 1,
    roles: null,
    phone: "050-8888888",
    email: "yhhh@y.com",
    planId: 1,
    plans: null,
    statusId: 1,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-02-12T00:00:00",
    fullName: "gsutuyt ytuyuyt",
  },
  {
    id: "15",
    firstName: "Elena",
    lastName: "etwuryewtut",
    account: undefined,
    passport: "123123123",
    roleId: 1,
    roles: null,
    phone: "050-7777777",
    email: "hey@ya.ru",
    planId: 1,
    plans: null,
    statusId: 2,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-03-12T00:00:00",
    fullName: "Elena etwuryewtut",
  },
  {
    id: "16",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "17",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "18",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "19",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "20",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: "חעחע",
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "21",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "050-8664603",
    email: "elena@bpreven.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: "lshadjh suugsai",
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "22",
    firstName: "Ann",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "050-8664603",
    email: "elena@bpreven.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "Ann Soloveva",
  },
  {
    id: "23",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "24",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "25",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "26",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "27",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "28",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "29",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "30",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "31",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: "hhh",
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "32",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "33",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "34",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://media.istockphoto.com/id/616901432/photo/hes-a-gifted-programmer.webp?b=1&s=170667a&w=0&k=20&c=b9qkh1UWOzo-jAniBx5xrGEkKMrM-ckgJEf9dNsHRyU=",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "35",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://media.istockphoto.com/id/616901432/photo/hes-a-gifted-programmer.webp?b=1&s=170667a&w=0&k=20&c=b9qkh1UWOzo-jAniBx5xrGEkKMrM-ckgJEf9dNsHRyU=",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "36",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "37",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "38",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0505913817",
    email: "tzahi556@gmail.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "39",
    firstName: "123123",
    lastName: "123123",
    account: undefined,
    passport: "123123123",
    roleId: 1,
    roles: null,
    phone: "050-8664603",
    email: "123@dmail.com",
    planId: 1,
    plans: null,
    statusId: 2,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "1980-03-12T00:00:00",
    fullName: "123123 123123",
  },
  {
    id: "40",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "050-8664603",
    email: "elena@bpreven.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: "nhsdasshdjsa ahdashf",
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "41",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "42",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "43",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "44",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: "0528664603",
    email: "test@bpreven.com",
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "45",
    firstName: "Elena",
    lastName: "Soloveva",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "Elena Soloveva",
  },
  {
    id: "46",
    firstName: "צחי",
    lastName: "חזן",
    account: undefined,
    passport: null,
    roleId: 0,
    roles: null,
    phone: undefined,
    email: undefined,
    planId: 0,
    plans: null,
    statusId: 0,
    status: null,
    tempCode: null,
    avatar:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    address: undefined,
    birth: null,
    fullName: "צחי חזן",
  },
  {
    id: "47",
    firstName: "מעין",
    lastName: "זולילי",
    account: undefined,
    passport: "454545456",
    roleId: 1,
    roles: null,
    phone: "0507665647",
    email: "maayan@gmail.com",
    planId: 1,
    plans: null,
    statusId: 2,
    status: null,
    tempCode: null,
    avatar:
      "https://media.istockphoto.com/id/1401980646/photo/3d-rendered-classic-sculpture-metaverse-avatar-with-network-of-low-poly-glowing-purple-lines.jpg?s=1024x1024&w=is&k=20&c=mf0nOLaHdiCZdkCRwTr2gKjypql-r6UBxpMqr0_Zqr0=",
    address: "העחינה לחיע דךיי",
    birth: "1980-12-28T00:00:00",
    fullName: "מעין זולילי",
  },
  {
    id: "48",
    firstName: "אלי",
    lastName: "צדחך",
    account: undefined,
    passport: "151515151",
    roleId: 1,
    roles: null,
    phone: "90508888888",
    email: "eli@bpreven.com",
    planId: 2,
    plans: null,
    statusId: 2,
    status: null,
    tempCode: null,
    avatar:
      "https://media.istockphoto.com/id/616901432/photo/hes-a-gifted-programmer.webp?b=1&s=170667a&w=0&k=20&c=b9qkh1UWOzo-jAniBx5xrGEkKMrM-ckgJEf9dNsHRyU=",
    address: undefined,
    birth: "1989-07-05T00:00:00",
    fullName: "אלי צדחך",
  },
  {
    id: "49",
    firstName: "ינון ",
    lastName: "איבגי",
    account: undefined,
    passport: "208187005",
    roleId: 1,
    roles: null,
    phone: "0542044915",
    email: "inonivgi8@gmail.com",
    planId: 2,
    plans: null,
    statusId: 2,
    status: null,
    tempCode: null,
    avatar: undefined,
    address: undefined,
    birth: "2023-09-26T00:00:00",
    fullName: "ינון  איבגי",
  },
];
export interface IUserAction {
  text: string;
  value: string;
  path: string;
  disabled?: boolean;
}
export const userActions: IUserAction[] = [
  { text: "עידכון נתונים", value: "editUserData", path: "update" },
  { text: "פרטים אישיים", value: "editPersonalData", path: "personal" },
  { text: "מסמכים", value: "documents", path: "documents" },
  { text: "מרשמים", value: "prescriptions", path: "prescriptions" },
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  { text: "תרגילים", value: "exercises", path: "exercises" },
];

export const userActionsEditShort: IUserAction[] = [
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  {
    text: "דוחות שאלונים",
    value: "questionsReports",
    path: "questions_reports",
  },
  { text: "פרטים אישיים", value: "editPersonalData", path: "personal" },
];
export const userActionsEdit: IUserAction[] = [
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  {
    text: "דוחות שאלונים",
    value: "questionsReports",
    path: "questions_reports",
  },
  { text: "פרטים אישיים", value: "editPersonalData", path: "personal" },
  { text: "תרגילים", value: "exercises", path: "exercises", disabled: true },
  { text: "תכנית טיפול", value: "treatmentPlan", path: "treatment_plan", disabled: true },
  { text: "עדכונים", value: "updates", path: "updates", disabled: true },
  { text: "דוח RRM", value: "rrm", path: "rrm", disabled: true },
  { text: "מסמכים", value: "documents", path: "documents", disabled: true },
  { text: "מרשמים", value: "prescriptions", path: "prescriptions", disabled: true },
  {
    text: "דוחות פריוונצ'ר",
    value: "prevencherReports",
    path: "prevencher_reports",
    disabled: true
  },
  { text: "דוחות טיפול", value: "treatmentReports", path: "treatment_reports", disabled: true },

  { text: "דוחות EMD", value: "emd", path: "emd", disabled: true },
  { text: "דוחות GAR", value: "gar", path: "gar", disabled: true },
];

export type TQuestionAnswer = {
  id: string;
  question: string;
  answer: string;
};
export interface IQuestionReport {
  questionnaireId: number;
  questionnaireName: string;
  date: Date;
  score: number;
  manager?: string;
  questions: TQuestionAnswer[];
}
export const questionReportsExample: IQuestionReport[] = [
  {
    questionnaireId: 1,
    questionnaireName: "שאלון כניסה",
    date: new Date(),
    score: 49,
    manager: "מייק הופמן",
    questions: [
      {
        id: "1",
        question: "האם Gender@ מטופל?",
        answer: "כן",
      },
      {
        id: "2",
        question: "האם הטיפול עזר לך?",
        answer: "לא",
      },
      {
        id: "1",
        question: "האם אתה בטוח?",
        answer: "לא",
      },
    ],
  },
  {
    questionnaireId: 2,
    date: new Date("12.12.2022"),
    score: 98,
    questionnaireName: "שאלון POC",
    questions: [
      {
        id: "1",
        question: "איך אני יכול לעזור לך?",
        answer: "אני אשמח לקבל עזרה",
      },
      {
        id: "2",
        question: "אוקי, אני אשמח לעזור לך, באיזה נושא תרצה לקבל עזרה?",
        answer: "טראומה",
      },
      {
        id: "3",
        question: "איך תרצה/י לקבל את העזרה",
        answer: "בטלפון",
      },
      {
        id: "4",
        question: "האם יש לך העדפה מגדרית בקבלת העזרה?",
        answer: "לא משנה לי כלל.",
      },
      {
        id: "5",
        question: "כמה זמן את/ה מתמודד/ת עם המצוקה שלך?",
        answer: "מעל חצי שנה",
      },
    ],
  },
  {
    questionnaireName: therapistPOCQuestionnaireName,
    questionnaireId: 100,
    manager: "מייק הופמן",
    date: new Date(),
    score: 87,
    questions: [],
  },
  {
    questionnaireName: therapistPOCQuestionnaireName,
    questionnaireId: 101,
    manager: "מייק הופמן",
    date: new Date("02.02.2023"),
    score: 45,
    questions: [],
  },
];
