import styles from "./therapistTreatmentPlan.module.css";
import React, { useEffect, useState } from "react";
import {
    mobileWidth,
} from "../../utils/constants";
import { getPrintDate } from "../../utils/utils";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
    useGetAllOpenTreatmentPlansMutation
} from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import {
    mediasSelector,
    therapistTreatmentPlansSelector,
    userSelector,
} from "../../services/selectors/user.selectors";
import { ITreatmentPlan, TLimits } from "../../types/types";
import Loader from "../loader/loader";
import ListPagination from "../listPagination/listPagination";
import TreatmentDocument from "../document/treatmentDocument";
import FilterButtonTreatmentPlan from "../filterButton/filterButtonTreatmentPlan";


function TherapistTreatmentPlan() {
    const therapist = useAppSelector(userSelector);
    const treatmentPlans = useAppSelector(therapistTreatmentPlansSelector)

    const medias = useAppSelector(mediasSelector);

    const mobile = useMediaQuery(mobileWidth);
    const [getAllTreatmentPlans, { isLoading: isTreatmentPlanLoading }] = useGetAllOpenTreatmentPlansMutation()

    const [treatmentDocumentToShow, setTreatmentDocumentToShow] = useState<ITreatmentPlan | null>(null);
    const [openTreatmentDocument, setOpenTreatmentDocument] = useState<boolean>(false);
    const [treatmentPlansToshow, setTreatmentPlansToshow] = useState<ITreatmentPlan[] | null | undefined>(treatmentPlans);
    const [treatmentPlanLimits, setTreatmentPlanLimits] = useState<TLimits>(
        {
            start: 1,
            end: 1
        });

    //limit inquiries to 7
    const limitNumber = 7;
    const isLoading = isTreatmentPlanLoading

    useEffect(() => {
        if (therapist.id) {
            getAllTreatmentPlans(
                {
                    companyId: therapist.companyId,
                    therapistName: therapist.fullName,
                    therapistId: therapist.id
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [therapist.id]);

    useEffect(() => {
        if (treatmentPlansToshow && treatmentPlansToshow.length > 0) {
            setTreatmentPlanLimits((prev) => ({
                ...prev,
                end:
                    limitNumber < treatmentPlansToshow.length
                        ? limitNumber
                        : treatmentPlansToshow.length,
            }));
        }
    }, [treatmentPlansToshow]);

    const showTreatmentDocument = (plan: ITreatmentPlan) => {
        setTreatmentDocumentToShow(plan)
        setOpenTreatmentDocument(true);
    }

    const setFilterTreatmentToShow = (treatments: ITreatmentPlan[] | undefined) => {
        setTreatmentPlansToshow(treatments)
    }

    return (
        <>
            {treatmentDocumentToShow && (
                <TreatmentDocument
                    treatmentPlan={treatmentDocumentToShow}
                    openDocument={openTreatmentDocument}
                    onClose={() => setOpenTreatmentDocument(false)}
                />
            )}
            <article className={styles.home__section}>
                <h4 className={styles.home__sectionTitle}>
                    תכניות הטיפול שלי          </h4>
                {treatmentPlans && treatmentPlans.length > 0
                    &&
                    <ul className={styles.inquiries}>
                        <li className={`${styles.inquiry} ${styles.inquiryHeader}`}>
                            <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                                {mobile ? "מס'" : "מס' תכנית"}
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                                {mobile ? "ת. פתיחה" : "תאריך פתיחה"}                        </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                                {mobile ? "שם" : "שם משתמש"}
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                                {mobile ? "תוכנית" : "שם תוכנית"}
                            </p>
                            <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                                {mobile ? "אופן" : "אופן הטיפול"}
                            </p>
                            <div
                                className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                            >
                                <FilterButtonTreatmentPlan
                                    documentsToFilter={treatmentPlans}
                                    setDocumentsToFilter={setFilterTreatmentToShow}
                                />
                            </div>

                        </li>
                    </ul>

                }
                {treatmentPlansToshow && treatmentPlansToshow.length > 0 ?
                    (
                        treatmentPlansToshow.map(
                            (i, index) =>
                                index >= treatmentPlanLimits.start - 1 &&
                                index < treatmentPlanLimits.end &&
                                (
                                    <li
                                        key={index}
                                        className={`${styles.inquiry} ${index % 2 === 0 && styles.inquiry_colored}
                                         ${!i.appointmentsProgram.statusId && styles.inquiry_canceled}   `}
                                        onClick={() => {
                                            i.appointmentsProgram.statusId && showTreatmentDocument(i)
                                        }}
                                        
                                    >
                                        <p
                                            className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                                        >
                                            {i.appointmentsProgram.id}
                                        </p>
                                        <p
                                            className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                                        >
                                            {`${getPrintDate(new Date(i.appointmentsProgram.createDate))}`}
                                        </p>
                                        <p
                                            className={`${styles.inquiryCell} ${styles.inquiryName}`}
                                        >
                                            {i.user.fullName || ""}
                                        </p>
                                        <p
                                            className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                                        >
                                            {i.appointmentsProgram.name || ""}
                                        </p>
                                        <p
                                            className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                                        >
                                            {medias.length ? (medias.find(media => media.Id === i.appointmentsProgram.mediasId))?.Name : ""}
                                        </p>
                                        <button
                                            disabled={!i.appointmentsProgram.statusId}
                                            className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                                        >
                                            פרטים
                                        </button>
                                        <span
                                            className={styles.inquiryRRM}
                                        />
                                        {!i.appointmentsProgram.statusId &&
                                            <span
                                                className={styles.inquiry_canceled_content}
                                            >
                                                התוכנית בוטלה
                                            </span>
                                        }
                                    </li>

                                )

                        )) : isLoading ? (
                            <div className={styles.text}>
                                <Loader />
                            </div>
                        ) : (
                        <p className={styles.text}>
                            לא נמצאו תוכניות טיפול         </p>
                    )}

            </article>
            {
                treatmentPlans && treatmentPlans.length > 0 && (
                    <ListPagination
                        limits={treatmentPlanLimits}
                        total={treatmentPlans?.length || 0}
                        setLimits={setTreatmentPlanLimits}
                        step={limitNumber}
                    />
                )
            }
        </>
    );
}

export default TherapistTreatmentPlan;
