import {
  IUserResponse,
  TSendInquiryUpdateRequest,
  TUpdateInquiryStatusRequest,
} from "../types/auth.types";
import {
  TQuestionnaireGroup,
  TGetUserDocumentsResponse,
  TInquiriesResponse,
  TMainPageUpdatesResponse,
  TUpdateUserDocumentStatusRequest,
  TUpdateUserRequest,
  TUpdateUserSettingsRequest,
  TMainPageInfo,
  TTakeInquiryRequest,
  TGetQuestionsReportByUserId,
  TQuestionnaireData,
  TGetAllQuestionsAndOptionsResponse,
  TInsertAllAnswersRequest,
  TAllUserQuestionnairesData,
  TGetMultipleQuestionnairesByUserIdAndCycleResponse,
  TGetMultipleQuestionnairesByUserIdAndCycleRequest,
  TAddMessageRequest,
  TGetStatusesResponse,
  TGetAllTherapistUsersResponse,
  IGenderOption,
  TTakeUserRequest,
  IChatAppointment,
  TQuestionnaireRequest,
  IPostChatAppointmentRequest,
  TUpdateChatAppointmentEntry,
  TCalendarAppointment,
  IMediaResponse,
  IMigzarResponse,
  TDefaultReportDataResponse,
  IGenderResponse,
  IChatAppointmentResponse,
  TTherapistUser,
} from "../types/user.types";
import { api } from "./api";
import {
  IAppointmentProgram,
  IAvailability,
  ITreatmentPlan,
} from "../../types/types";
import { IResponse, IRole } from "../types";
import { BASE_URL } from "../../utils/constants";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, void>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
    }),
    getRoleIdsList: build.query<IRole[], void>({
      query: () => ({
        url: `/General/GetAllTherapistsRolesLists`,
        method: "GET",
      }),
    }),
    getUsers: build.query<TGetAllTherapistUsersResponse, string | number>({
      query: (TherapistsId) => ({
        url: `Therapists/Therapist_GetALLUsers/${TherapistsId}`,
        method: "GET",
      }),
    }),
    takeUser: build.mutation<TGetAllTherapistUsersResponse, TTakeUserRequest>({
      query: (body) => ({
        url: `Therapists/Therapist_SetUser`,
        method: "POST",
        body,
      }),
    }),
    updateUser: build.mutation<IUserResponse, TUpdateUserRequest>({
      query: ({ body, id }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getMainPageUpdates: build.query<TMainPageUpdatesResponse, void>({
      query: () => ({
        url: `/psychologists/updates`,
        method: "GET",
      }),
    }),
    getInquiries: build.query<TInquiriesResponse, void>({
      query: () => ({
        url: `/psychologists/inquiries`,
        method: "GET",
      }),
    }),
    takeInquiry: build.mutation<IResponse, TTakeInquiryRequest>({
      query: (body) => ({
        url: `Therapists/Therapist_TakeUserRequest`,
        method: "POST",
        body,
      }),
    }),
    updateInquiryStatus: build.mutation<
      TInquiriesResponse,
      TUpdateInquiryStatusRequest
    >({
      query: (body) => ({
        url: `/psychologists/inquiries`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    sendInquiryUpdate: build.mutation<
      TInquiriesResponse,
      TSendInquiryUpdateRequest
    >({
      query: (body) => ({
        url: `/psychologists/inquiries`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getUserByGuid: build.query<TTherapistUser, string>({
      query: (Guid) => ({
        url: `user/User_GetUserByGuid/${Guid}`,
        method: "GET",
      }),
    }),
    updateUserSettings: build.mutation<
      IUserResponse,
      TUpdateUserSettingsRequest
    >({
      query: ({ body, id }) => ({
        url: `user/settings/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getUserDocuments: build.query<TGetUserDocumentsResponse, string>({
      query: (id) => ({
        url: `/users/${id}/documents`,
        method: "GET",
      }),
    }),
    changeUserDocumentStatus: build.mutation<
      TGetUserDocumentsResponse,
      TUpdateUserDocumentStatusRequest
    >({
      query: ({ id, documentId, status }) => ({
        url: `/users/${id}/documents`,
        method: "POST",
        body: { documentId, status },
      }),
    }),
    fetchDocks: build.query<IResponse, void>({
      query: () => ({
        url: `/users/docs`,
        method: "GET",
      }),
    }),
    sendFile: build.mutation<IResponse, FormData>({
      query: (body) => ({
        url: `/users/docs`,
        method: "POST",
        body,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    fetchPrescriptions: build.query<IResponse, void>({
      query: () => ({
        url: `/users/prescriptions`,
        method: "GET",
      }),
    }),
    updatePrescription: build.mutation<IResponse, string>({
      query: (prescriptionId) => ({
        url: `/users/prescriptions`,
        method: "POST",
        body: { prescriptionId },
      }),
    }),
    getAllQuestions: build.query<TQuestionnaireGroup, void>({
      query: () => ({
        url: `https://bprevenserver.dgtracking.co.il/GetallQuestions`,
        method: "GET",
      }),
    }),
    getMainPageInfo: build.query<TMainPageInfo, string | number>({
      query: (therapistId) => ({
        url: `/Therapists/Therapist_GetUserInsertQuestionnaire/${therapistId}`,
        method: "GET",
      }),
    }),
    getReportQuestionsAndOptionsByQuestionnaireId: build.query<
      TGetAllQuestionsAndOptionsResponse,
      number
    >({
      query: (questionnaireId) => ({
        url: `Questions/GetAllQuestionsAndOptionsByQuestionnaireId/${questionnaireId}`,
        method: "GET",
      }),
    }),
    getDefaultReportData: build.query<
      TDefaultReportDataResponse,
      string | number
    >({
      query: (userGuid) => ({
        url: `Therapists/Therapist_GetDefaultReport/${userGuid}`,
        method: "GET",
      }),
    }),
    getQuestionsReportByUserId: build.query<
      TQuestionnaireData,
      TGetQuestionsReportByUserId
    >({
      query: ({ questionnaireId, userId, cycle }) => ({
        url: `Questions/GetQuestionsReportsByUserId/${questionnaireId}/${userId}/${cycle}`,
        method: "GET",
      }),
    }),
    getAllQuestionnairesByUserId: build.query<
      TAllUserQuestionnairesData,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `Questions/GetQuestionnairesByUsersId/${id}`,
        method: "GET",
      }),
    }),
    getMultipleQuestionnairesByUserIdAndCycle: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      TGetMultipleQuestionnairesByUserIdAndCycleRequest
    >({
      query: ({ UsersId, Cycle, CompanyId, QuestionnaireCompaniesId }) => ({
        url: `Questions/GetMultiplesReportsByUserId/${CompanyId}/${QuestionnaireCompaniesId}/${UsersId}/${Cycle}`,
        method: "GET",
      }),
    }),
    getRRMData: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      void
    >({
      query: () => ({
        url: `Questions/GetMultiplesReportsByUserId`,
        method: "GET",
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      boolean,
      {
        answers: TInsertAllAnswersRequest[];
        companyId: number;
        questionnaireCompaniesId?: number;
        therapistsId: number | string;
        appointmentsId?: number;
      }
    >({
      query: ({
        answers,
        companyId,
        questionnaireCompaniesId = 1,
        appointmentsId = 0,
        therapistsId,
      }) => ({
        url: `/Questions/InsertAllAnswersQuestions/${companyId}/${questionnaireCompaniesId}/${appointmentsId}/${therapistsId}`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
      invalidatesTags: ["user"],
    }),
    addMessageToScore: build.mutation<boolean, TAddMessageRequest>({
      query: (answers) => ({
        url: `/Score/Score_AddMessageToScore`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
    }),
    getMessageByUsersIdAndCycle: build.query<
      boolean,
      { userId: string; Cycle: number }
    >({
      query: ({ userId, Cycle }) => ({
        url: `/GetMessageByUserIdAndCycle/${userId}/${Cycle}`,
        method: "GET",
      }),
    }),
    getChatAppointments: build.query<IChatAppointment[], string | number>({
      query: (TherapistsId) => ({
        url: `${BASE_URL}Appointments/Appointments_GetAllAppointmentsByTherapist/${TherapistsId}`,
        method: "GET",
      }),
    }),
    getChatAppointmentsByUserId: build.query<
      IChatAppointment[],
      { UsersId: string | number; TherapistsId: string | number }
    >({
      query: ({ UsersId, TherapistsId }) => ({
        url: `Appointments/Appointments_GetAppointmentsByIds/${UsersId}/${TherapistsId}`,
        method: "GET",
      }),
    }),
    addChatAppointment: build.mutation<
      IChatAppointmentResponse,
      { Num: number | null; body: IPostChatAppointmentRequest }
    >({
      query: ({ Num, body }) => ({
        url: `Appointments/Appointments_PostAppointments/2/${Num ?? ""}`,
        method: "POST",
        body,
      }),
    }),
    updateChatAppointment: build.mutation<
      boolean,
      { body: TUpdateChatAppointmentEntry[]; appointmentId: string | number }
    >({
      query: ({ body, appointmentId }) => ({
        url: `Appointments/${appointmentId}`,
        method: "PATCH",
        body,
      }),
    }),
    sendUserEnteredSMSToTherapist: build.query<
      { usersId: number },
      { userId: string | number; appointmentId: string | number }
    >({
      query: ({ userId, appointmentId }) => ({
        url: `/Appointments/Appointments_UserEnterToChat/${appointmentId}/${userId}`,
        method: "GET",
      }),
    }),
    getStatuses: build.query<TGetStatusesResponse, void>({
      query: () => ({
        url: `/General/Gen_GetTable/statusrequest/0`,
        method: "GET",
      }),
    }),
    getMedias: build.query<IMediaResponse, void>({
      query: () => ({
        url: `/General/Gen_GetTable/medias/0`,
        method: "GET",
      }),
    }),
    getMigzars: build.query<IMigzarResponse, void>({
      query: () => ({
        url: `/General/Gen_GetTable/migzars/0`,
        method: "GET",
      }),
    }),
    getGenders: build.query<IGenderResponse, void>({
      query: () => ({
        url: `/General/Gen_GetTable/genders/0`,
        method: "GET",
      }),
    }),
    getAllGenders: build.query<IGenderOption[], void>({
      query: () => ({
        url: `user/getAllGenders`,
        method: "GET",
      }),
    }),
    postInquiryUpdate: build.mutation<string, TQuestionnaireRequest>({
      query: (body: TQuestionnaireRequest) => ({
        url: `/Therapists/Therapist_PostCommentsToRequest`,
        method: "POST",
        body,
        responseHandler: "text",
      }),
    }),
    postTherapistAvailability: build.mutation<IAvailability[], IAvailability[]>(
      {
        query: (body: IAvailability[]) => ({
          url: `/Therapists/TherapistsAvailability_DML/2`,
          method: "POST",
          body: body,
        }),
      }
    ),
    getTherapistAvailability: build.mutation<
      IAvailability[],
      { TherapistId: number; CompanyId: number }
    >({
      query: ({ TherapistId, CompanyId }) => ({
        url: `/Therapists/TherapistsAvailability_DML/1`,
        method: "POST",
        body: [{ therapistsid: TherapistId, companyid: CompanyId }],
      }),
    }),
    getAllAppointments: build.query<
      TCalendarAppointment[],
      {
        therapistId: string | number;
        month: string | number;
        year: string | number;
      }
    >({
      query: ({ therapistId, month, year }) => ({
        url: `Appointments/Appointments_GetAppointmentsByMonth/${therapistId}/${month}/${year}`,
        method: "GET",
      }),
    }),
    getAllOpenTreatmentPlans: build.mutation<
      ITreatmentPlan[],
      { companyId: number; therapistName: string; therapistId: number | string }
    >({
      query: ({ companyId, therapistName, therapistId }) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/1`,
        method: "POST",
        body: {
          Name: therapistName,
          therapistId: therapistId,
          companyId: companyId,
        },
      }),
    }),

    updateTherapistTakingTreatmentPlan: build.mutation<
      TCalendarAppointment[],
      IAppointmentProgram
    >({
      query: (treatmentPlan) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/6`,
        method: "POST",
        body: treatmentPlan,
      }),
    }),
    alternativeAppointmentProgramDate: build.mutation<
      TCalendarAppointment[],
      IAppointmentProgram
    >({
      query: (treatmentPlan) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/9`,
        method: "POST",
        body: treatmentPlan,
      }),
    }),

    getAllAppointmentsInProgram: build.mutation<
      TCalendarAppointment[],
      IAppointmentProgram
    >({
      query: (treatmentPlan) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/10`,
        method: "POST",
        body: treatmentPlan,
      }),
    }),
    cancleAppointmentProgram: build.mutation<
      TCalendarAppointment[],
      IAppointmentProgram
    >({
      query: (treatmentPlan) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/8`,
        method: "POST",
        body: treatmentPlan,
      }),
    }),
    udpateCommentsInAppointmentProgram: build.mutation<
      TCalendarAppointment[],
      IAppointmentProgram
    >({
      query: (treatmentPlan) => ({
        url: `/Appointments/AppointmentsPrograms_PostAppointments/2`,
        method: "POST",
        body: treatmentPlan,
      }),
    }),
    sendSMSToChat: build.query<
      IResponse,
      {
        AppointmentId: number;
        UsersId?: number | string;
        TherapistsId?: number;
      }
    >({
      query: ({ AppointmentId, UsersId, TherapistsId }) => ({
        url: `/Appointments/Appointments_UserEnterToChat/${AppointmentId}/${
          UsersId ?? 0
        }/${TherapistsId ?? 0}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetRoleIdsListQuery,
  useLazyGetUsersQuery,
  useLazyGetUserByGuidQuery,
  useGetAllGendersQuery,
  useGetMainPageUpdatesQuery,
  useGetInquiriesQuery,
  useGetUserDocumentsQuery,
  useTakeUserMutation,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useSendInquiryUpdateMutation,
  useChangeUserDocumentStatusMutation,
  useTakeInquiryMutation,
  useFetchDocksQuery,
  useFetchPrescriptionsQuery,
  useGetAllQuestionsQuery,
  useGetMainPageInfoQuery,
  useLazyGetMainPageInfoQuery,
  useGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useLazyGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useGetDefaultReportDataQuery,
  useLazyGetDefaultReportDataQuery,
  useGetQuestionsReportByUserIdQuery,
  useLazyGetQuestionsReportByUserIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useGetAllQuestionnairesByUserIdQuery,
  useLazyGetAllQuestionnairesByUserIdQuery,
  useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery,
  useGetRRMDataQuery,
  useAddMessageToScoreMutation,
  useGetMessageByUsersIdAndCycleQuery,
  useGetStatusesQuery,
  useGetMediasQuery,
  useGetMigzarsQuery,
  useGetGendersQuery,
  useGetChatAppointmentsQuery,
  useLazyGetChatAppointmentsQuery,
  useAddChatAppointmentMutation,
  usePostInquiryUpdateMutation,
  useLazySendUserEnteredSMSToTherapistQuery,
  useUpdateChatAppointmentMutation,
  usePostTherapistAvailabilityMutation,
  useGetTherapistAvailabilityMutation,
  useGetAllAppointmentsQuery,
  useLazyGetAllAppointmentsQuery,
  useGetAllOpenTreatmentPlansMutation,
  useUpdateTherapistTakingTreatmentPlanMutation,
  useAlternativeAppointmentProgramDateMutation,
  useGetAllAppointmentsInProgramMutation,
  useCancleAppointmentProgramMutation,
  useUdpateCommentsInAppointmentProgramMutation,
  useLazySendSMSToChatQuery,
} = userApi;
