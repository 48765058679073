import React, { useState, useContext, useEffect } from "react";
import { SocketContext } from "../../SocketContext";
import { Category } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import VideoCallWindow from "../modals/videoCallWindow/videoCallWindow";
import { useAppSelector } from "../../services/hooks";
import { isAuthSelector } from "../../services/selectors/auth.selectors";
import {
  useLazyGetChatAppointmentsQuery,
  useGetUserQuery,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import Modal from "../modals/modal/Modal";
import InactivityWarning from "../modals/inactivityWarning/inactivityWarning";
import InactivityLogoutPopup from "../modals/inactivityLogoutPopup/inactivityLogoutPopup";
import {
  showUserJoinedModalSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import UserJoinedChatSuccessPopup from "../modals/userJoinedChatSuccessPopup/userJoinedChatSuccessPopup";
import { useActions } from "../../services/hooks/useActions";
import { useRoutes } from "../../hooks/useRoutes";
import MediaDevicesErrorPopup from "../modals/mediaDevicesErrorPopup/mediaDevicesErrorPopup";
import { chatUsersSelector } from "../../services/selectors/chat.selector";

function App() {
  const { isLoading: isUserLoading } = useGetUserQuery();

  const mobile = useMediaQuery(mobileWidth);
  const isAuth = useAppSelector(isAuthSelector);
  const therapist = useAppSelector(userSelector);
  const chatUsers = useAppSelector(chatUsersSelector);
  //ws
  const showUserJoinedModal = useAppSelector(showUserJoinedModalSelector);

  //video calls
  const {
    startCall,
    setStartCall,
    callAccepted,
    showMediaDevicesErrorPopup,
    setShowMediaDevicesErrorPopup,
  } = useContext(SocketContext);

  const [showWarning, setShowWarning] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userJoined, setUserJoined] = useState<null | string>(null);

  // useInactivityTimer(setShowWarning, setShowLogoutModal);

  //get all appointments
  //get all therapist`s users
  const [getChatAppointments] = useLazyGetChatAppointmentsQuery();
  useEffect(() => {
    if (isAuth && therapist.id) {
      getChatAppointments(therapist.id);
    }
  }, [isAuth, therapist.id, getChatAppointments]);

  const { resetShowUserJoinedModal } = useActions();

  useEffect(() => {
    if (showUserJoinedModal) {
      const user = chatUsers.find(
        (u) => String(u.userId) === String(showUserJoinedModal)
      );
      user && setUserJoined(user.fullName);
    }
  }, [showUserJoinedModal, chatUsers]);

  //menu
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeCategory, setActiveCategory] = useState(Category.Main);
  const changeCategory = (e: React.MouseEvent, cat: Category) => {
    if (!mobile) {
      e?.stopPropagation();
    }
    setActiveCategory(cat);
  };

  const routes = useRoutes(isAuth, changeCategory);

  return (
    <>
      {isAuth && (startCall || callAccepted) && (
        <VideoCallWindow onClose={() => setStartCall(false)} />
      )}
      {isAuth && showWarning && (
        <Modal onClose={() => setShowWarning(false)}>
          <InactivityWarning onClose={() => setShowWarning(false)} />
        </Modal>
      )}
      {isAuth && showMediaDevicesErrorPopup && (
        <Modal onClose={() => setShowMediaDevicesErrorPopup(false)}>
          <MediaDevicesErrorPopup
            onClose={() => setShowMediaDevicesErrorPopup(false)}
          />
        </Modal>
      )}
      {showLogoutModal && (
        <Modal onClose={() => setShowLogoutModal(false)}>
          <InactivityLogoutPopup onClose={() => setShowLogoutModal(false)} />
        </Modal>
      )}
      {isAuth && userJoined && (
        <Modal
          onClose={() => {
            setUserJoined(null);
            resetShowUserJoinedModal();
          }}
        >
          <UserJoinedChatSuccessPopup
            userName={userJoined}
            onClose={() => {
              setUserJoined(null);
              resetShowUserJoinedModal();
            }}
          />
        </Modal>
      )}
      {isUserLoading ? <Loader /> : routes}
    </>
  );
}

export default App;
