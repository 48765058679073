import * as Yup from "yup";
import { israeliMobileRegExp } from "./constants";

export const signUpValidationSchema1 = Yup.object().shape({
  firstName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם הפרטי צריך לכלול לפחות שני תווים"),
  lastName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם משפחה צריך לכלול לפחות שני תווים"),
  passport: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(9, "תעודת זהות צריכה להכיל 9 תווים")
    .max(9, "תעודת זהות צריכה להכיל 9 תווים"),
});

export const signUpValidationSchema2 = Yup.object().shape({
  email: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .email("אימייל לא תקין"),
  phone: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(7, "טלפון נייד לא תקין")
    .max(7, "טלפון נייד לא תקין"),
  birth: Yup.date()
    .required("אנא השלם את השדות המסומנים")
    .min(new Date(1920, 0, 1), "תאריך לידה לא תקין")
    .max(new Date(), "תאריך לידה לא תקין"),
});

export const loginEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .email("אימייל לא תקין"),
  password: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(6, "הסיסמה צריכה להכיל לפחות 6 תווים"),
  roleId: Yup.string()
  .required("אנא השלם את השדות המסומנים")

});

export const loginSMSValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required("נא להזין מספר נייד")
    .matches(israeliMobileRegExp, "מספר נייד לא תקין"),
});

export const changeAvatarValidationSchema = Yup.object().shape({
  link: Yup.string()
    .url("קישור לא תקין")
    .required("אנא השלם את השדות המסומנים"),
});

export const restorePasswordValidationSchema = Yup.object().shape({
  email_or_phone: Yup.string()
    .required('נא להזין דוא"ל או מספר נייד')
    .test("email_or_phone", "מספר נייד או אימייל לא תקין", (value) => {
      if (value.includes("@")) {
        return validateEmail(value);
      } else return /^05\d([-]?)\d{7}$/.test(value);
    }),
});

const validateEmail = (email: string) => {
  return Yup.string().email().isValidSync(email);
};
export const updateUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם הפרטי צריך לכלול לפחות שני תווים"),
  lastName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם משפחה צריך לכלול לפחות שני תווים"),
  email: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .email("אימייל לא תקין"),
  phone: Yup.string().required("אנא השלם את השדות המסומנים").min(9).max(11),
  degree: Yup.string(),
  about: Yup.string(),
});

export const addNewUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("אנא השלם את השדות המסומנים")
    .min(2, "השם הפרטי צריך לכלול לפחות שני תווים"),
  lastName: Yup.string().min(2, "השם משפחה צריך לכלול לפחות שני תווים"),
  phone: Yup.string().matches(israeliMobileRegExp, "מספר נייד לא תקין"),
  email: Yup.string().email("אימייל לא תקין"),
  birth: Yup.date()
    .min(new Date(1920, 0, 1), "תאריך לידה לא תקין")
    .max(new Date(), "תאריך לידה לא תקין"),
  passport: Yup.string()
    .min(9, "תעודת זהות צריכה להכיל 9 תווים")
    .max(9, "תעודת זהות צריכה להכיל 9 תווים"),
});
