import styles from "../document/document.module.css";
import React, { useState } from "react";
import {
  useAddChatAppointmentMutation,
  useLazyGetChatAppointmentsQuery,
  useLazyGetMainPageInfoQuery,
  useLazySendSMSToChatQuery,
} from "../../services/api/user.api";
import Modal from "../modals/modal/Modal";
import ChatAppointmentSuccessPopup from "../modals/chatAppountmentSuccessPopup/chatAppointmentSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import { formatDateTime } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";

interface IAppointmentButtonProps {
  userId: string;
  media: string;
  name: string;
  appointmentsId?: number | null;
  disabled?: boolean;
  Num?: number;
  propsStyles?: { [key: string]: string };
}
const AppointmentButton = ({
  userId,
  media = "1",
  name = "הזמן צ'אט",
  appointmentsId,
  disabled,
  Num,
  propsStyles,
}: IAppointmentButtonProps) => {
  const therapist = useAppSelector(userSelector);

  const [addChatAppointment] = useAddChatAppointmentMutation();
  const [sendSMSToChat] = useLazySendSMSToChatQuery();
  const [getAllAppointments] = useLazyGetChatAppointmentsQuery();
  const [getMainPageInfo] = useLazyGetMainPageInfoQuery();

  const [openAppointmentModalSuccess, setOpenAppointmentModalSuccess] =
    useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const sendSMSToUser = (AppointmentId: number, UsersId: number | string) => {
    sendSMSToChat({
      AppointmentId: AppointmentId,
      UsersId: UsersId
    }).then((res) => {
      if ("data" in res) {
        setOpenAppointmentModalSuccess(true)
      } else {
        setOpenModalError(true);
      }
    })
      .finally(() => {
        getAllAppointments(therapist.id);
        getMainPageInfo(therapist.id)
      });
  }

  const postAppointment = () => {
    if (appointmentsId) {
      sendSMSToUser(appointmentsId, userId);
    }
    else {
      addChatAppointment({
        Num: Num ?? null,
        body: {
          UsersId: userId,
          TherapistsId: therapist.id,
          AppointmentDate: formatDateTime(new Date()),
          MediaId: media,
          Active: 1,
          CompanyId: therapist.companyId,
          StatusId: 1,
        }
      })
        .then((res) => {
          if ("data" in res) {
            sendSMSToUser(res.data.id, res.data.usersId);
          } else {
            setOpenModalError(true);
          }
        })
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        type={"button"}
        className={
          propsStyles
            ? `${propsStyles.editOption} ${propsStyles.editOptionBtn} hover`
            : `button button_white ${styles.btn} ${styles.btn_type_actionReport} ${disabled && styles.btn_disabled} ${styles.chatBtn} hover`
        }
        onClick={postAppointment}
      >
        {name}
      </button>
      {openAppointmentModalSuccess && (
        <Modal onClose={() => setOpenAppointmentModalSuccess(false)}>
          <ChatAppointmentSuccessPopup
            onClose={() => setOpenAppointmentModalSuccess(false)}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </>
  );
};

export default AppointmentButton;
