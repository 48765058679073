import React, { Dispatch, SetStateAction } from "react";
import styles from "./listPagination.module.css";
import { TLimits } from "../../types/types";

interface IListPaginationProps {
  limits: TLimits;
  total: number;
  step: number;
  setLimits: Dispatch<SetStateAction<TLimits>> | Dispatch<SetStateAction<{ FakeComapnyId: number | string, limit: TLimits }[] | undefined>>;
  groupKey?: number | string;
}
function ListPagination({
  limits,
  total,
  setLimits,
  step,
  groupKey
}: IListPaginationProps) {
  const onNextClick = () => {
    if (limits.end < total) {
      if (!groupKey) {
        (setLimits as Dispatch<SetStateAction<TLimits>>)((prev) => ({
          ...prev,
          start: prev.start + step,
          end: prev.end + step < total ? prev.end + step : total,
        }));
      }
      else {
        (setLimits as Dispatch<SetStateAction<{ FakeComapnyId: number | string, limit: TLimits }[]>>)(
          (prev) => {
            return prev.map(li => {
              if ((groupKey == null && li.FakeComapnyId === "null") || (li.FakeComapnyId === groupKey)) {
                return {
                  ...li,
                  limit: {
                    start: li.limit.start + step,
                    end: li.limit.end + step < total ? li.limit.end + step : total
                  }
                }
              }
              else {
                return li
              }
            })
          })
      };
    }
  }
  const onPrevClick = () => {
    if (limits.start > 1) {
      if (!groupKey) {
        (setLimits as Dispatch<SetStateAction<TLimits>>)((prev) => ({
          ...prev,
          start: prev.start - step,
          end: prev.start - 1,
        }));
      }
      else {
        (setLimits as Dispatch<SetStateAction<{ FakeComapnyId: number | string, limit: TLimits }[]>>)(
          (prev) => {
            return prev.map(li => {
              if ((groupKey == null && li.FakeComapnyId === "null") || (li.FakeComapnyId === groupKey)) {
                return {
                  ...li,
                  limit: {
                    start: li.limit.start - step,
                    end: li.limit.start - 1
                  }
                }
              }
              else {
                return li
              }
            })
          })
      };
    }
  };
  return (
    <article className={styles.pagination}>
      <button
        className={`${styles.btn} ${styles.btn_forward}`}
        onClick={onPrevClick}
      />
      מוצג {limits.start} - {limits.end < total ? limits.end : total} מתוך {total}
      <button
        className={`${styles.btn} ${styles.btn_backward}`}
        onClick={onNextClick}
      />
    </article>
  );
}

export default ListPagination;
