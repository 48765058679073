import React, {
  createContext,
  useState,
  useRef,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import Peer from "simple-peer";
import { ICall } from "./types/ws.types";
import { HubConnection } from "@microsoft/signalr";
import { useAppSelector } from "./services/hooks";
import { isAuthSelector } from "./services/selectors/auth.selectors";
import { chatConnectionSelector } from "./services/selectors/chat.selector";
import { END_CALL_CODE } from "./utils/constants";
import { userSelector } from "./services/selectors/user.selectors";
import { useLazyInitializeConnectionQuery } from "./services/api/chat.api";

export interface ChatSocketCtxState {
  call: ICall;
  setCall: (call: ICall) => void;
  callAccepted: boolean;
  setCallAccepted: Dispatch<SetStateAction<boolean>>;
  endCall: () => void;
  // callEnded: string | undefined;
  // setCallEnded: Dispatch<SetStateAction<string | undefined>>;
  openModalCallEnded: boolean;
  setOpenModalCallEnded: Dispatch<SetStateAction<boolean>>;
  stream: MediaStream | undefined;
  setStream: Dispatch<SetStateAction<MediaStream | undefined>>;
  anotherUserStream: MediaStream | undefined;
  setAnotherUserStream: Dispatch<SetStateAction<MediaStream | undefined>>;
  myVideo: React.MutableRefObject<HTMLVideoElement | null>;
  userVideo: React.MutableRefObject<HTMLVideoElement | null>;
  callUser: (
    socket: HubConnection,
    to: { name: string; id: number },
    from: { name: string; avatar: string | null }
  ) => void;
  leaveCall: () => void;
  // answerCall: (socket: HubConnection) => void;
  startCall: boolean;
  setStartCall: Dispatch<SetStateAction<boolean>>;
  showMediaDevicesErrorPopup: boolean;
  setShowMediaDevicesErrorPopup: Dispatch<SetStateAction<boolean>>;
}

const SocketContext = createContext<ChatSocketCtxState>(
  {} as ChatSocketCtxState
);
export const videoCallInitialState: ICall = {
  isReceivingCall: false,
  from: {
    name: "",
    // title: "",
    avatar: "",
  },
  signal: { type: "offer" },
  to: { name: "", id: 0 },
};

const ContextProvider = ({ children }: { children: ReactNode }) => {
  const isAuth = useAppSelector(isAuthSelector);
  const connection = useAppSelector(chatConnectionSelector);
  const therapist = useAppSelector(userSelector);

  const [callAccepted, setCallAccepted] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [anotherUserStream, setAnotherUserStream] = useState<MediaStream>();
  const [call, setCall] = useState<ICall>(videoCallInitialState);
  const [startCall, setStartCall] = useState(false);
  const [openModalCallEnded, setOpenModalCallEnded] = useState(false);
  const [showMediaDevicesErrorPopup, setShowMediaDevicesErrorPopup] =
    useState(false);

  const myVideo = useRef<HTMLVideoElement>(null);
  const userVideo = useRef<HTMLVideoElement>(null);
  const connectionRef = useRef<Peer.Instance | null>();

  //WS Connection
  const [connect] = useLazyInitializeConnectionQuery();
  useEffect(() => {
    if (isAuth && therapist.id) {
      connect(therapist.id);
    }
  }, [isAuth, therapist.id, connect]);

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((currentStream) => {
  //       setStream(currentStream);
  //       if (myVideo?.current) {
  //         myVideo.current.srcObject = currentStream;
  //       }
  //     })
  //     .catch((error) => {
  //       // setShowMediaDevicesErrorPopup(true);
  //       console.error("Error accessing media devices:", error);
  //     });
  // }, [myVideo, setStream]);

  // const answerCall = (socket: HubConnection) => {
  //   setCallAccepted(true);
  //   //
  //   const peer = new Peer({ initiator: false, trickle: false, stream });
  //   console.log(1000, peer, call);
  //
  //   peer.on("signal", (data) => {
  //     console.log(1001, data, call);
  //     socket.invoke("answerCall", {
  //       signal: data,
  //       to: call.from.callerSocketId,
  //     });
  //   });
  //
  //   peer.on("stream", (currentStream) => {
  //     console.log(1002, currentStream);
  //     if (userVideo.current) userVideo.current.srcObject = currentStream;
  //   });
  //
  //   peer.signal(call.signal);
  //
  //   connectionRef.current = peer;
  // };
  // const handleReceiveOffer = (offer: string) => {
  //   console.log(1002);
  //   connectionRef.current = new Peer({
  //     initiator: false,
  //     trickle: false,
  //     stream: myVideo.current?.srcObject as MediaStream,
  //   });
  //
  //   connectionRef.current.on("signal", (data: Peer.SignalData) => {
  //     connection?.invoke("SendAnswer", 199, JSON.stringify(data));
  //   });
  //
  //   connectionRef.current.signal(offer);
  //
  //   connectionRef.current.on("stream", (stream: MediaStream) => {
  //     if (userVideo.current) {
  //       userVideo.current.srcObject = stream;
  //     }
  //   });
  // };
  const callUser = async (
    socket: HubConnection,
    to: { name: string; id: number },
    from: { name: string; avatar: string | null }
  ) => {
    setStartCall(true);
    try {
      const currentStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (myVideo.current) {
        myVideo.current.srcObject = currentStream;
      }
      setStream(currentStream);
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: currentStream,
        config: {
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" },
            { urls: "stun:stun.services.mozilla.com" },
          ],
        },
      });
      setCall({ ...videoCallInitialState, to, from });

      peer.on("signal", (data: Peer.SignalData) => {
        socket.invoke("SendOffer", to.id, from, JSON.stringify(data));
      });

      peer.on("stream", (currentStream) => {
        setAnotherUserStream(currentStream);
        if (userVideo.current) userVideo.current.srcObject = currentStream;
        console.log("set", userVideo.current);
      });
      //unsubscribe from socket event
      peer.on("close", () => {
        console.log("peer closed");
        socket.off("ReceiveAnswer");
        if (connectionRef.current) {
          connectionRef.current = null; // Ensure no dangling references
        }
      });

      socket.on("ReceiveAnswer", (signal) => {
        setCallAccepted(true);
        const parsed = JSON.parse(signal);
        if (peer && parsed.type === "answer") {
          peer.signal(signal);
        }
      });
      connectionRef.current = peer;
      peer.on("error", (err) => console.error("Peer error:", err));
    } catch (err) {
      console.log(err);
      setShowMediaDevicesErrorPopup(true);
    }
  };

  const endCall = () => {
    if (connection) {
      connection.invoke("SendMessage", {
        senderId: Number(therapist.id),
        type: END_CALL_CODE,
        content: `${END_CALL_CODE}:by:${call.from.name}`,
        receiverId: call.to.id,
      });
      leaveCall();
    }
  };
  const leaveCall = () => {
    setCall(videoCallInitialState);
    setCallAccepted(false);
    setStartCall(false);
    setOpenModalCallEnded(true);
    if (connectionRef.current) {
      connectionRef.current.destroy();
      connectionRef.current = null; // Prevent further signaling
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(undefined);
    }
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        setCall,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        // callEnded,
        callUser,
        leaveCall,
        // answerCall,
        startCall,
        setStartCall,
        // setCallEnded,
        setCallAccepted,
        openModalCallEnded,
        setOpenModalCallEnded,
        anotherUserStream,
        setAnotherUserStream,
        showMediaDevicesErrorPopup,
        setShowMediaDevicesErrorPopup,
        endCall,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
