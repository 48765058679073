import styles from "./editPersonalDataPage.module.css";
import React, { useEffect, useState } from "react";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { therapistUsersSelector, userSelector } from "../../services/selectors/user.selectors";
import { TTherapistUser } from "../../services/types/user.types";
import { useLazyGetUserByGuidQuery, useLazyGetUsersQuery } from "../../services/api/user.api";
import { capitalizeKeys, getPrintFormatDate} from "../../utils/utils"


function EditPersonalDataPage() {
  const navigate = useNavigate();

  const location = useLocation();
  const id = location.state.UserDetails.UsersId;

  const therapist = useAppSelector(userSelector)
  const therapistUsers = useAppSelector(therapistUsersSelector);

  const [user, setUser] = useState<TTherapistUser>(therapistUsers.find((u: TTherapistUser) => u.Id === id))

  const [getAllTherapistUsers] = useLazyGetUsersQuery();
  const [getUserByGuid] = useLazyGetUserByGuidQuery();

  useEffect(() => {
    if (!therapistUsers.length)
      getAllTherapistUsers(therapist.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapist.id, getAllTherapistUsers]);

  useEffect(() => {
    if (therapistUsers.length > 0) {
      const currentUser = therapistUsers.find((u: TTherapistUser) => u.Id === id)
      if (currentUser)
        setUser(currentUser)
      else {
        location.state.UserDetails?.Guid && getUserByGuid(location.state.UserDetails?.Guid).then(res => {
          if ("data" in res && res.data !== undefined) {
            const capitalizedUser = capitalizeKeys(res.data) as TTherapistUser;
            setUser(capitalizedUser);
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapistUsers]);



  // const user = therapistUsers.find((u: TTherapistUser) => u.Id === id);
  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h2 className={styles.title}>משתמש קיים: פרטים אישיים</h2>
      <div className={styles.content}>
        <div className={styles.personal}>
          <h4 className={styles.permissionsTitle}>פרטים אישיים</h4>
          <img
            src={`${user?.Avatar ? user.Avatar : avatarPlaceholder}`}
            alt="תמונת משתמש"
            className={styles.avatarPlaceholder}
          />
          {user?.Account && (
            <p className={styles.accountNumber}>מספר משתמש {user.Account}</p>
          )}
          <form className={styles.personalForm}>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="firstName"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                שם פרטי
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                readOnly={true}
                value={user?.FirstName || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="lastName"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                שם משפחה
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                readOnly={true}
                value={user?.LastName || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="passport"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                תעודת זהות
              </label>
              <input
                type="text"
                name="passport"
                id="passport"
                readOnly={true}
                value={user?.Passport || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="birth"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                תאריך לידה
              </label>
              <input
                type="date"
                name="birth"
                id="birth"
                readOnly={true}
                value={user?.Birth ? getPrintFormatDate(user.Birth) : ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="country"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                מדינה
              </label>
              <input
                type="text"
                name="country"
                id="country"
                readOnly={true}
                value={""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="address"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                כתובת מגורים
              </label>
              <input
                type="text"
                name="address"
                id="address"
                readOnly={true}
                value={user?.Address || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="email"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                אימייל
              </label>
              <input
                type="text"
                name="email"
                id="email"
                readOnly={true}
                value={user?.Email || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="phone"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                נייד
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                readOnly={true}
                value={user?.Phone || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
          </form>
        </div>
        <div className={styles.permissions}>
          <h4 className={styles.permissionsTitle}>הרשאות מנוי</h4>
          <form className={styles.permissionsForm}>
            <label htmlFor="company" className={`${styles.label}`}>
              שיוך חברה
            </label>
            <input
              type="text"
              name="company"
              id="company"
              value="דיסקונט"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="permission" className={`${styles.label}`}>
              סוג הרשאה
            </label>
            <input
              type="text"
              name="permission"
              id="permission"
              value="מנוי"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="plan" className={`${styles.label}`}>
              סוג מנוי
            </label>
            <input
              type="text"
              name="plan"
              id="plan"
              value="פרימיום"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="status" className={`${styles.label}`}>
              סטטוס
            </label>
            <input
              type="text"
              name="status"
              id="status"
              value="פעיל"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="instructor" className={`${styles.label}`}>
              שיוך מדריך
            </label>
            <input
              type="text"
              name="instructor"
              id="instructor"
              value="ינון איבגי"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="therapist" className={`${styles.label}`}>
              שיוך מטפל
            </label>
            <input
              type="text"
              name="therapist"
              id="therapist"
              value="ינון איבגי"
              readOnly={true}
              className={`${styles.input}`}
            />
          </form>
        </div>
      </div>
      <p className={`${styles.text}`}>
        רק תמיכה טכנית רשאית לערוך נתונים אישיים
      </p>
    </article>
  );
}
export default EditPersonalDataPage;
