import popupStyles from "./appointmentCard.module.css";
import { getDuration } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import React from "react";
import { TCalendarAppointment } from "../../../services/types/user.types";

interface IAppointmentCardProps {
  appointmentData: TCalendarAppointment;
}
function AppointmentCard({ appointmentData }: IAppointmentCardProps) {
  const navigate = useNavigate();

  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>פרטי פגישה</h2>
      <p className={popupStyles.popup__text}>שם המטופל</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.users?.fullName}
      </p>
      <p className={popupStyles.popup__text}>תאריך פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {`${appointmentData.appointmentDate.getDate()}/${appointmentData.appointmentDate.getMonth() + 1
          }/${appointmentData.appointmentDate.getFullYear()}`}
      </p>
      <p className={popupStyles.popup__text}>שעת פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.startTime && appointmentData.endTime
          ? `${appointmentData.startTime} - ${appointmentData.endTime}`
          : getDuration(appointmentData.appointmentDate, 50)}
      </p>
      <p className={popupStyles.popup__text}>אופן הפגישה</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.mediaId === 1
          ? "צ'אט"
          : appointmentData.mediaId === 2
            ? "וידאו"
            : "טלפון"}
      </p>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} ${popupStyles.popup__nextBtn} hover`}
        onClick={() =>
          navigate(`/messages`, {
            state: { pathname: appointmentData.users?.id },
          })
        }
      >
        להתחיל פגישה
      </button>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} button hover`}
        onClick={() => navigate(`/users/personal`, {
          state: {
            UserDetails: {
              UsersId: appointmentData.users?.id,
              Guid: appointmentData.users?.guid
            }
          }
        })}
      >
        לראות פרטים אישיים
      </button>
    </article>
  );
}

export default AppointmentCard;
